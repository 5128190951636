<main>
    <h1>
        <img src="logotipo.png">
        <span>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3532_644)">
                    <path
                        d="M15.5832 13.9745V20.2738C15.5832 20.3549 15.5617 20.4346 15.5209 20.5046C15.48 20.5747 15.4214 20.6327 15.3508 20.6727C15.2803 20.7126 15.2004 20.7332 15.1193 20.7322C15.0382 20.7312 14.9588 20.7087 14.8893 20.6671L10.9998 18.3332L7.11042 20.6671C7.04079 20.7088 6.96133 20.7313 6.88017 20.7322C6.799 20.7332 6.71904 20.7125 6.64846 20.6724C6.57788 20.6323 6.51921 20.5742 6.47846 20.504C6.43771 20.4338 6.41632 20.3541 6.4165 20.2729V13.9754C5.23047 13.0258 4.36866 11.7313 3.95017 10.2707C3.53169 8.81016 3.57718 7.25567 4.08037 5.82208C4.58356 4.38849 5.5196 3.14658 6.75914 2.26798C7.99867 1.38939 9.4805 0.91748 10.9998 0.91748C12.5192 0.91748 14.001 1.38939 15.2405 2.26798C16.4801 3.14658 17.4161 4.38849 17.9193 5.82208C18.4225 7.25567 18.468 8.81016 18.0495 10.2707C17.631 11.7313 16.7692 13.0258 15.5832 13.9754V13.9745ZM8.24984 15.0497V17.8456L10.9998 16.1956L13.7498 17.8456V15.0497C12.8761 15.4031 11.9423 15.5843 10.9998 15.5832C10.0574 15.5843 9.12356 15.4031 8.24984 15.0497ZM10.9998 13.7499C12.4585 13.7499 13.8575 13.1704 14.8889 12.139C15.9204 11.1075 16.4998 9.70859 16.4998 8.2499C16.4998 6.7912 15.9204 5.39226 14.8889 4.36081C13.8575 3.32936 12.4585 2.7499 10.9998 2.7499C9.54115 2.7499 8.1422 3.32936 7.11075 4.36081C6.0793 5.39226 5.49984 6.7912 5.49984 8.2499C5.49984 9.70859 6.0793 11.1075 7.11075 12.139C8.1422 13.1704 9.54115 13.7499 10.9998 13.7499Z"
                        fill="#23272E" />
                    <circle cx="10.9998" cy="8.11304" r="3.62622" fill="#23272E" />
                </g>
                <defs>
                    <clipPath id="clip0_3532_644">
                        <rect width="22" height="22" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            &nbsp;
            Controle de Qualidade
        </span>
        <section class="buttons">
            <a class="white-background" href="/controle-dados">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.6666 17.3335V2.66683H2.66659V16.4168C2.66659 16.6599 2.76316 16.8931 2.93507 17.065C3.10698 17.2369 3.34014 17.3335 3.58325 17.3335H13.6666ZM16.4166 19.1668H3.58325C2.85391 19.1668 2.15443 18.8771 1.63871 18.3614C1.12298 17.8456 0.833252 17.1462 0.833252 16.4168V1.75016C0.833252 1.50705 0.929829 1.27389 1.10174 1.10198C1.27365 0.930073 1.5068 0.833496 1.74992 0.833496H14.5833C14.8264 0.833496 15.0595 0.930073 15.2314 1.10198C15.4033 1.27389 15.4999 1.50705 15.4999 1.75016V8.16683H19.1666V16.4168C19.1666 17.1462 18.8769 17.8456 18.3611 18.3614C17.8454 18.8771 17.1459 19.1668 16.4166 19.1668ZM15.4999 10.0002V16.4168C15.4999 16.6599 15.5965 16.8931 15.7684 17.065C15.9403 17.2369 16.1735 17.3335 16.4166 17.3335C16.6597 17.3335 16.8929 17.2369 17.0648 17.065C17.2367 16.8931 17.3333 16.6599 17.3333 16.4168V10.0002H15.4999ZM4.49992 4.50016H9.99992V10.0002H4.49992V4.50016ZM6.33325 6.3335V8.16683H8.16659V6.3335H6.33325ZM4.49992 10.9168H11.8333V12.7502H4.49992V10.9168ZM4.49992 13.6668H11.8333V15.5002H4.49992V13.6668Z"
                        fill="white" />
                </svg>
            </a>
            <a class="white-background" href="/perfil">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.0002 19.1668C4.93741 19.1668 0.833496 15.0629 0.833496 10.0002C0.833496 4.93741 4.93741 0.833496 10.0002 0.833496C15.0629 0.833496 19.1668 4.93741 19.1668 10.0002C19.1668 15.0629 15.0629 19.1668 10.0002 19.1668ZM5.42875 15.7348C6.72633 16.7722 8.33887 17.3361 10.0002 17.3335C11.806 17.3335 13.4587 16.6808 14.7366 15.5992C14.1396 14.9866 13.4258 14.5 12.6374 14.1681C11.8491 13.8362 11.0022 13.6657 10.1468 13.6668C9.26003 13.6658 8.38271 13.8491 7.57051 14.2051C6.75831 14.5611 6.02896 15.082 5.42875 15.7348ZM4.14816 14.4185C4.91858 13.6009 5.84828 12.9497 6.87996 12.5052C7.91164 12.0606 9.02344 11.832 10.1468 11.8335C11.23 11.8321 12.3028 12.0447 13.3036 12.459C14.3044 12.8734 15.2135 13.4813 15.9787 14.248C16.7634 13.1435 17.2268 11.8432 17.3175 10.4914C17.4082 9.13959 17.1226 7.78906 16.4924 6.58966C15.8623 5.39027 14.9121 4.3889 13.7475 3.69665C12.5828 3.00441 11.2491 2.64835 9.89438 2.66798C8.53966 2.68761 7.21685 3.08216 6.07272 3.80786C4.92859 4.53356 4.00788 5.56203 3.41271 6.77918C2.81755 7.99633 2.57122 9.35456 2.70104 10.7032C2.83086 12.0518 3.33175 13.3381 4.14816 14.4194V14.4185ZM10.0002 10.9168C9.0277 10.9168 8.09507 10.5305 7.40744 9.84289C6.7198 9.15525 6.3335 8.22262 6.3335 7.25016C6.3335 6.2777 6.7198 5.34507 7.40744 4.65744C8.09507 3.9698 9.0277 3.5835 10.0002 3.5835C10.9726 3.5835 11.9053 3.9698 12.5929 4.65744C13.2805 5.34507 13.6668 6.2777 13.6668 7.25016C13.6668 8.22262 13.2805 9.15525 12.5929 9.84289C11.9053 10.5305 10.9726 10.9168 10.0002 10.9168ZM10.0002 9.0835C10.4864 9.0835 10.9527 8.89034 11.2965 8.54653C11.6403 8.20271 11.8335 7.73639 11.8335 7.25016C11.8335 6.76393 11.6403 6.29762 11.2965 5.9538C10.9527 5.60998 10.4864 5.41683 10.0002 5.41683C9.51393 5.41683 9.04762 5.60998 8.7038 5.9538C8.35998 6.29762 8.16683 6.76393 8.16683 7.25016C8.16683 7.73639 8.35998 8.20271 8.7038 8.54653C9.04762 8.89034 9.51393 9.0835 10.0002 9.0835Z"
                        fill="#6F7480" />
                </svg>
            </a>
            <button (click)="logout()" class="red">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 14.1146L22.6 7.51465L24.4853 9.39998L17.8853 16L24.4853 22.6L22.6 24.4853L16 17.8853L9.39998 24.4853L7.51465 22.6L14.1146 16L7.51465 9.39998L9.39998 7.51465L16 14.1146Z"
                        fill="#23272E" />
                </svg>
            </button>
        </section>
    </h1>

    <div>
        <label>
            Linha
            <select id="linha" (change)="filterByLinha()">
                <option>TODAS</option>
            </select>
        </label>
        <label>
            <span>Grupo <i id="whySelectGrupo" class="fa-regular fa-circle-question tooltip"></i></span>
            <select id="grupo" (change)="filterByGrupo()">
                <option>TODOS</option>
            </select>
        </label>
        <label>
            Categoria
            <select id="categoria" (change)="filterByCategoria()">
                <option>TODAS</option>
            </select>
        </label>
        <label>
            <span>Lote</span>
            <div>
                <select id="lote" (change)="filterByLote()">
                    <option>TODOS</option>
                </select>
            </div>
        </label>
        <label>
            <span style="color: #2853E8;">Produto</span>
            <div>
                <select id="produto">
                </select>
            </div>
        </label>
        <label>
            Turno
            <select id="turno">
                <option>TODOS</option>
                <option>Primeiro Turno</option>
                <option>Segundo Turno</option>
            </select>
        </label>
        <label>Menor Peso (g) <input id="minPeso" type="number" min="0" [defaultValue]="0"></label>
        <label>Maior Peso (g)<input id="maxPeso" type="number" max="60" [defaultValue]="60"></label>
        <label>Data inicial <input id="initialDate" type="date"></label>
        <label>Data Final <input id="finalDate" type="date"></label>
        <button id="add_products" (click)="addsDataToCharts()" [disabled]="isLoading">Filtrar</button>
    </div>

    <div id="added_products">
        <span *ngIf="this.added_products.length <= 0">Selecione um <strong style="color: #2853E8;">Produto</strong> e
            clique em <strong style="color: #2853E8;">Filtrar</strong> para começar</span>
        <span *ngFor="let product of added_products">{{product}} <button (click)="removeDataToCharts(product)"><i
                    class="fa-solid fa-x"></i></button></span>
    </div>

    <section>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 1</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart1"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart1" popover>
                <h3>GRÁFICO 1</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart1" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 2</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart2"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart2" popover>
                <h3>GRÁFICO 2</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart2" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 3</h3>
            <hr>
            <div>
                <div></div>
            </div>
            <button popovertarget="chart3"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart3" popover>
                <h3>GRÁFICO 3</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <div></div>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart3" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 4</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart4"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart4" popover>
                <h3>GRÁFICO 4</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart4" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div class="smalldiv" [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 5</h3>
            <hr>
            <div>
                <div class="table"></div>
            </div>
            <button popovertarget="chart5"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart5" popover>
                <h3>GRÁFICO 5</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <div class="table"></div>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart5" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 6</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart6"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart6" popover>
                <h3>GRÁFICO 6</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart6" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
        <div [attr.aria-disabled]="isLoading">
            <h3>GRÁFICO 7</h3>
            <hr>
            <div>
                <canvas></canvas>
            </div>
            <button popovertarget="chart7"><i class="fa-solid fa-up-right-and-down-left-from-center"></i></button>
            <div id="chart7" popover>
                <h3>GRÁFICO 7</h3>
                <span id="description"></span>
                <hr>
                <div>
                    <canvas></canvas>
                </div>
                <div id="infos"></div>
                <hr>
                <button popovertarget="chart7" popovertargetaction="hide">Fechar</button>
            </div>
        </div>
    </section>

    <div class="tooltip"></div>

</main>