import { Component } from '@angular/core';
import { QuickAccessButtonsComponent } from "../quick-access-buttons/quick-access-buttons.component";
import { LoginService } from '../login.service';
import axios from 'axios';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'insights',
  standalone: true,
  imports: [QuickAccessButtonsComponent, CommonModule],
  templateUrl: './insights.component.html',
  styleUrl: './insights.component.css'
})
export class InsightsComponent {

  constructor(private title: Title, private sanitizer: DomSanitizer, private loginService: LoginService) {
    this.title.setTitle('Insights - Predicta');
  }

  data: any = [{ "insights": "Carregando...", "data": "Carregando..." }, { "insights": "Carregando...", "data": "Carregando..." }, { "insights": "Carregando...", "data": "Carregando..." }];
  isLoading: boolean = true;

  async getData() {
    this.isLoading = true;

    try {
      const response = await axios.get(environment.apiUrl + '/insights', { headers: { Authorization: 'Bearer ' + this.loginService.token } });
      const data = response.data;
      this.data = data.sort((a: any, b: any) => new Date(b.data).getTime() - new Date(a.data).getTime());
    } catch (error) {
      this.data = [];
    } finally {
      this.isLoading = false;
    }
  }

  formatDate(date: string) {
    date += 'T00:00:00';
    return new Date(date).toLocaleDateString('pt-BR');
  }

  ngOnInit() {
    this.loginService.ifLoggedIn(this.getData.bind(this));
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}
