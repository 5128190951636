import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';
import 'chartjs-adapter-moment';
import * as echarts from 'echarts';
import { environment } from '../../environments/environment';
import { LoadingComponent } from '../loading/loading.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'controle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './controle.component.html',
  styleUrl: './controle.component.css'
})
export class ControleComponent {
  isLoading = true;

  constructor(private title: Title) {
    this.title.setTitle('Controle Predicta');
  }

  product_infos: any[] = [];
  datas: any[] = [];
  splitteddatas: any[] = [];
  avgdatas: any[] = [];

  datasFiltered: any[] = [];
  splitteddatasFiltered: any[] = [];
  avgdatasFiltered: any[] = [];

  added_products: string[] = [];

  charts: any[] = [];
  boxplotExtendedChart: any;
  token = '';

  async getDatas() {
    this.isLoading = true;

    const data = (await axios.get(environment.apiUrl + '/dados/raw', { headers: { "authorization": `Bearer ${this.token}` } })).data;
    this.datas = data;
    const data_product_infos = (await axios.get(environment.apiUrl + '/produtos', { headers: { "authorization": `Bearer ${this.token}` } })).data;
    this.product_infos = data_product_infos;

    this.defineSelects(data, data_product_infos);

    this.avgdatas = (await axios.get(environment.apiUrl + '/dados/average', { headers: { "authorization": `Bearer ${this.token}` } })).data;
    this.splitteddatas = (await axios.get(environment.apiUrl + '/dados/splitted', { headers: { "authorization": `Bearer ${this.token}` } })).data;

    this.isLoading = false;
  }

  getNameByCode(code: string) {
    const produto = this.product_infos.find((data) => data['Código'] === code);
    return produto ? produto['Produto'] : 'DESCONHECIDO';
  }

  defineSelects(data: any[], data_product_infos: any[]) {
    this.defineLinha(data_product_infos);
    this.defineGrupo(data_product_infos);
    this.defineCategoria(data);
    this.defineProduto(data);
    this.defineLote(data);
  }

  defineLinha(datas: any) {
    const selectLinha = document.querySelector('#linha') as HTMLSelectElement;
    const linhas: number[] = [...new Set(datas.map((data: any) => data['Linha']))] as number[];
    linhas.sort((a: number, b: number) => a - b);
    linhas.forEach((linha: any) => {
      const option = document.createElement('option');
      option.value = linha;
      option.innerText = linha;
      selectLinha.appendChild(option);
    });
  }

  defineGrupo(datas: any) {
    const selectGrupo = document.querySelector('#grupo') as HTMLSelectElement;
    const grupos: string[] = [...new Set(datas.map((data: any) => data['Grupo']))] as string[];
    grupos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    grupos.forEach((grupo: string) => {
      const option = document.createElement('option');
      option.value = grupo;
      option.innerText = grupo;
      selectGrupo.appendChild(option);
    });
  }

  defineCategoria(datas: any) {
    const selectCategoria = document.querySelector('#categoria') as HTMLSelectElement;
    const categorias: string[] = [...new Set(datas.map((data: any) => data['Categoria']))] as string[];
    categorias.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    categorias.forEach((categoria: string) => {
      const option = document.createElement('option');
      option.value = categoria;
      option.innerText = categoria;
      selectCategoria.appendChild(option);
    });
  }

  defineProduto(datas: any) {
    const selectProduto = document.querySelector('#produto') as HTMLSelectElement;
    const produtos: string[] = [...new Set(datas.map((data: any) => data['Produto']))] as string[];
    produtos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    produtos.forEach((produto: string) => {
      const option = document.createElement('option');
      option.value = produto;
      option.innerText = produto + ' (' + this.getNameByCode(produto) + ')';
      selectProduto.appendChild(option);
    }
    );
  }

  defineLote(datas: any) {
    const selectLote = document.querySelector('#lote') as HTMLSelectElement;
    const lotes: string[] = [...new Set(datas.map((data: any) => data['LOTE']))] as string[];
    selectLote.innerHTML = '';
    const all_lotes_option = document.createElement('option');
    all_lotes_option.value = 'TODOS';
    all_lotes_option.innerText = 'TODOS';
    selectLote.appendChild(all_lotes_option);
    lotes.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    lotes.forEach((lote: string) => {
      const option = document.createElement('option');
      option.value = lote;
      option.innerText = lote;
      selectLote.appendChild(option);
    });
  }

  defineProdutoByGrupo() {
    if ((document.querySelector('#grupo') as HTMLSelectElement).value == 'TODOS') {
      const selectProduto = document.querySelector('#produto') as HTMLSelectElement;
      selectProduto.innerHTML = '';
      const produtos: string[] = [...new Set(this.datas.map((data) => data['Produto']))] as string[];
      produtos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
      produtos.forEach((produto) => {
        const option = document.createElement('option');
        option.value = produto;
        option.innerText = produto + ' (' + this.getNameByCode(produto) + ')';
        selectProduto.appendChild(option);
      });
      return;
    }
    const grupo_name = (document.querySelector('#grupo') as HTMLSelectElement).selectedOptions[0].innerText;
    const all_products_by_grupo = this.product_infos.filter((data) => data['Grupo'] == grupo_name).map((data) => data['Código']);
    const selectProduto = document.querySelector('#produto') as HTMLSelectElement;
    selectProduto.innerHTML = '';
    const produtos: string[] = [...new Set(this.datas.filter((data) => all_products_by_grupo.includes(data['Produto'])).map((data) => data['Produto']))] as string[];
    produtos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    produtos.forEach((produto) => {
      const option = document.createElement('option');
      option.value = produto;
      option.innerText = produto + ' (' + this.getNameByCode(produto) + ')';
      selectProduto.appendChild(option);
    });
  }

  defineCategoriaByProduto() {
    if ((document.querySelector('#grupo') as HTMLSelectElement).value == 'TODOS') {
      const selectCategoria = document.querySelector('#categoria') as HTMLSelectElement;
      selectCategoria.innerHTML = '';
      const all_categorias_option = document.createElement('option');
      all_categorias_option.value = 'TODAS';
      all_categorias_option.innerText = 'TODAS';
      selectCategoria.appendChild(all_categorias_option);
      const categorias: string[] = [...new Set(this.datas.map((data) => data['Categoria']))] as string[];
      categorias.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
      categorias.forEach((categoria: string) => {
        const option = document.createElement('option');
        option.value = categoria;
        option.innerText = categoria;
        selectCategoria.appendChild(option);
      });
      return;
    }
    const selectCategoria = document.querySelector('#categoria') as HTMLSelectElement;
    selectCategoria.innerHTML = '';
    const pre_filtered_products = Array.from((document.querySelector('#produto') as HTMLSelectElement).options).map(option => option.value);
    const categorias: string[] = [...new Set(this.datas.filter((data) => pre_filtered_products.includes(data['Produto'])).map((data) => data['Categoria']))] as string[];
    categorias.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    categorias.forEach((categoria: string) => {
      const option = document.createElement('option');
      option.value = categoria;
      option.innerText = categoria;
      selectCategoria.appendChild(option);
    });
  }

  defineLoteByProduto() {
    if ((document.querySelector('#produto') as HTMLSelectElement).value == 'TODOS') {
      const selectLote = document.querySelector('#lote') as HTMLSelectElement;
      selectLote.innerHTML = '';
      const all_lotes_option = document.createElement('option');
      all_lotes_option.value = 'TODOS';
      all_lotes_option.innerText = 'TODOS';
      selectLote.appendChild(all_lotes_option);
      const lotes: string[] = [...new Set(this.datas.map((data) => data['LOTE']))] as string[];
      lotes.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
      lotes.forEach((lote: string) => {
        const option = document.createElement('option');
        option.value = lote;
        option.innerText = lote;
        selectLote.appendChild(option);
      });
      return;
    }
    const produto = (document.querySelector('#produto') as HTMLSelectElement).value;
    const selectLote = document.querySelector('#lote') as HTMLSelectElement;
    selectLote.innerHTML = '';
    const all_lotes_option = document.createElement('option');
    all_lotes_option.value = 'TODOS';
    all_lotes_option.innerText = 'TODOS';
    selectLote.appendChild(all_lotes_option);
    const pre_filtered_products = Array.from((document.querySelector('#produto') as HTMLSelectElement).options).map(option => option.value);
    const lotes: string[] = [...new Set(this.datas.filter((data) => pre_filtered_products.includes(data['Produto'])).map((data) => data['LOTE']))] as string[];
    lotes.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    lotes.forEach((lote: string) => {
      const option = document.createElement('option');
      option.value = lote;
      option.innerText = lote;
      selectLote.appendChild(option);
    });
  }

  filterByLinha() {
    if ((document.querySelector('#linha') as HTMLSelectElement).value == 'TODAS') {
      const selectGrupo = document.querySelector('#grupo') as HTMLSelectElement;
      selectGrupo.innerHTML = '';
      const all_grupos_option = document.createElement('option');
      all_grupos_option.value = 'TODOS';
      all_grupos_option.innerText = 'TODOS';
      selectGrupo.appendChild(all_grupos_option);
      const grupos: string[] = [...new Set(this.product_infos.map((data) => data['Grupo']))] as string[];
      grupos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
      const codigos: string[] = [...new Set(this.product_infos.map((data) => data['Código']))] as string[];
      grupos.forEach((grupo: any, index: number) => {
        const option = document.createElement('option');
        option.value = codigos[index];
        option.innerText = grupo;
        selectGrupo.appendChild(option);
      });
      this.filterByGrupo();
      return;
    }
    const linha = (document.querySelector('#linha') as HTMLSelectElement).value;
    const selectGrupo = document.querySelector('#grupo') as HTMLSelectElement;
    selectGrupo.innerHTML = '';
    const grupos: string[] = [...new Set(this.product_infos.filter((data) => data['Linha'] == linha).map((data) => data['Grupo']))] as string[];
    grupos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    const codigos: string[] = [...new Set(this.product_infos.filter((data) => data['Linha'] == linha).map((data) => data['Código']))] as string[];
    grupos.forEach((grupo: any, index: number) => {
      const option = document.createElement('option');
      option.value = codigos[index];
      option.innerText = grupo;
      selectGrupo.appendChild(option);
    });
    this.filterByGrupo();
  }

  filterByGrupo() {
    this.defineProdutoByGrupo();
    this.defineCategoriaByProduto();
    this.defineLoteByProduto();
  }

  filterByCategoria() {
    this.defineProdutoByGrupo();
    const categoria = (document.querySelector('#categoria') as HTMLSelectElement).value;
    if (categoria == 'TODAS') {
      const selectProduto = document.querySelector('#produto') as HTMLSelectElement;
      const pre_filtered_products = Array.from((document.querySelector('#produto') as HTMLSelectElement).options).map(option => option.value);
      const produtos: string[] = [...new Set(this.datas.filter((data) => pre_filtered_products.includes(data['Produto'])).map((data) => data['Produto']))] as string[];
      selectProduto.innerHTML = '';
      produtos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
      produtos.forEach((produto) => {
        const option = document.createElement('option');
        option.value = produto;
        option.innerText = produto + ' (' + this.getNameByCode(produto) + ')';
        selectProduto.appendChild(option);
      });
      this.defineLoteByProduto();
      return;
    }
    const selectProduto = document.querySelector('#produto') as HTMLSelectElement;
    const pre_filtered_products = Array.from((document.querySelector('#produto') as HTMLSelectElement).options).map(option => option.value);
    const produtos: string[] = [...new Set(this.datas.filter((data) => pre_filtered_products.includes(data['Produto']) && data['Categoria'] == categoria).map((data) => data['Produto']))] as string[];
    selectProduto.innerHTML = '';
    produtos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    produtos.forEach((produto) => {
      const option = document.createElement('option');
      option.value = produto;
      option.innerText = produto + ' (' + this.getNameByCode(produto) + ')';
      selectProduto.appendChild(option);
    });
    this.defineLoteByProduto();
  }

  filterByLote() {
    this.defineProdutoByGrupo();
    const lotes = [...(document.querySelector('#lote') as HTMLSelectElement).selectedOptions as any].map(option => option.value);
    if (lotes.length == 0 || lotes.includes('TODOS')) {
      const pre_filtered_products = Array.from((document.querySelector('#produto') as HTMLSelectElement).options).map(option => option.value);
      const produtos: string[] = [...new Set(this.datas.filter((data) => pre_filtered_products.includes(data['Produto'])).map((data) => data['Produto']))] as string[];
      const selectProduto = document.querySelector('#produto') as HTMLSelectElement;
      selectProduto.innerHTML = '';
      produtos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
      produtos.forEach((produto) => {
        const option = document.createElement('option');
        option.value = produto;
        option.innerText = produto + ' (' + this.getNameByCode(produto) + ')';
        selectProduto.appendChild(option);
      });
      return;
    }
    const selectProduto = document.querySelector('#produto') as HTMLSelectElement;
    const pre_filtered_products = Array.from((document.querySelector('#produto') as HTMLSelectElement).options).map(option => option.value);
    const produtos: string[] = [...new Set(this.datas.filter((data) => pre_filtered_products.includes(data['Produto']) && lotes.includes(data['LOTE'])).map((data) => data['Produto']))] as string[];
    selectProduto.innerHTML = '';
    produtos.sort((a: string, b: string) => a.trim().localeCompare(b.trim()));
    produtos.forEach((produto) => {
      const option = document.createElement('option');
      option.value = produto;
      option.innerText = produto + ' (' + this.getNameByCode(produto) + ')';
      selectProduto.appendChild(option);
    });
  }

  filterData(datas: any[]) {
    let filteredDatas: any[] = [];

    // Filter data by produto
    const selectproduto = document.querySelector('#produto') as HTMLSelectElement;
    const produto = selectproduto.value;
    filteredDatas = datas.filter((data) => data['Produto'] == produto);

    // Filter data by lote
    const selectlote = document.querySelector('#lote') as HTMLSelectElement;
    const lote = selectlote.value;
    if (lote != 'TODOS') filteredDatas = filteredDatas.filter((data) => data['LOTE'] == lote);

    // Filter data by turno
    const turno = (document.querySelector('#turno') as HTMLSelectElement).value;
    filteredDatas = filteredDatas.filter((data) => {
      if (turno == 'TODOS') return true;
      if (turno == 'Primeiro Turno') return data['Nome do(a) responsável'] != 'Dayane';
      if (turno == 'Segundo Turno') return data['Nome do(a) responsável'] == 'Dayane';
      return false;
    });

    // Filter data by peso
    const minPeso = (document.querySelector('#minPeso') as HTMLInputElement).value;
    const maxPeso = (document.querySelector('#maxPeso') as HTMLInputElement).value;
    filteredDatas = filteredDatas.filter((data) => {
      const peso = parseFloat(data['AMOSTRA']);
      return peso >= parseFloat(minPeso) && peso <= parseFloat(maxPeso);
    });

    // Filter data by initial date
    const initalDate = (document.querySelector('#initialDate') as HTMLInputElement).value;
    if (initalDate == '') null;
    else
      filteredDatas = filteredDatas.filter((data) => {
        const dateStr = (data['Datetime de início de preenchimento'] as string).split('T')[0];
        const [year, month, day] = dateStr.split('-');

        const formattedDate = `${year}-${month}-${day}`;
        const dateMS = new Date(formattedDate).getTime();

        const initalDateMS = new Date(initalDate).getTime();

        return dateMS >= initalDateMS;
      });

    // Filter data by final date
    const finalDate = (document.querySelector('#finalDate') as HTMLInputElement).value;
    if (finalDate == '') null;
    else
      filteredDatas = filteredDatas.filter((data) => {
        const dateStr = (data['Datetime de início de preenchimento'] as string).split('T')[0];
        const [year, month, day] = dateStr.split('-');

        const formattedDate = `${year}-${month}-${day}`;
        const dateMS = new Date(formattedDate).getTime();

        const finalDateMS = new Date(finalDate).getTime();

        return dateMS <= finalDateMS;
      });

    return { filteredDatas, produto };
  }

  transformData(datas: any[]) {
    let transformedData: any = {};

    datas.forEach(item => {
      const produto = item["Produto"];
      const dataHora = item["Datetime de início de preenchimento"];
      const amostra = item["AMOSTRA"];
      const produtofinal = item['PRODUTO FINAL'];

      if (!transformedData[produto]) {
        transformedData[produto] = {
          produto: produto,
          data: []
        };
      }

      transformedData[produto].data.push({
        "Datetime de início de preenchimento": dataHora,
        "AMOSTRA": amostra,
      });

      if (produtofinal) transformedData[produto].data[transformedData[produto].data.length - 1]['PRODUTO FINAL'] = produtofinal;
    });

    return Object.values(transformedData);
  }

  addsDataToCharts() {
    const { filteredDatas, produto } = this.filterData(this.datas);
    const { filteredDatas: splittedFilteredDatas } = this.filterData(this.splitteddatas);
    const { filteredDatas: avgFilteredDatas } = this.filterData(this.avgdatas);

    // Check if product is already added
    if (this.added_products.includes(produto)) {
      // Find how many times this product has been added before
      const count = this.added_products.filter(p => p.startsWith(produto)).length;

      // Add product with counter
      const productWithCounter = `${produto} (${count})`;
      this.added_products.push(productWithCounter);

      // Add filtered data with modified product name
      this.datasFiltered.push(...filteredDatas.map(data => ({
        ...data,
        Produto: productWithCounter
      })));

      this.splitteddatasFiltered.push(...splittedFilteredDatas.map(data => ({
        ...data,
        Produto: productWithCounter
      })));

      this.avgdatasFiltered.push(...avgFilteredDatas.map(data => ({
        ...data,
        Produto: productWithCounter
      })));
    } else {
      // First time adding this product
      this.added_products.push(produto);
      this.datasFiltered.push(...filteredDatas);
      this.splitteddatasFiltered.push(...splittedFilteredDatas);
      this.avgdatasFiltered.push(...avgFilteredDatas);
    }

    this.mountCharts(this.datasFiltered, this.splitteddatasFiltered, this.avgdatasFiltered, this.product_infos);
  }

  removeDataToCharts(name: string) {
    // Remove from added_products
    const index = this.added_products.indexOf(name);
    if (index > -1) {
      this.added_products.splice(index, 1);
    }

    // Remove from filtered data arrays
    this.datasFiltered = this.datasFiltered.filter(data => data.Produto !== name);
    this.splitteddatasFiltered = this.splitteddatasFiltered.filter(data => data.Produto !== name);
    this.avgdatasFiltered = this.avgdatasFiltered.filter(data => data.Produto !== name);

    // Remount charts with updated data
    this.mountCharts(this.datasFiltered, this.splitteddatasFiltered, this.avgdatasFiltered, this.product_infos);
  }

  mountCharts(datas: any[], splitteddatas: any[], avgdatas: any[], product_infos: any[]) {

    // Clear all charts

    this.charts.forEach((chart) => { try { chart.destroy() } catch (e) { chart.dispose() } })

    // Chart 1 - Scatter Chart 

    const transformedDatas = this.transformData(avgdatas);

    const grupoName = (document.querySelector('#grupo') as HTMLSelectElement).value;
    const produto_infos = product_infos.find((data) => data['Grupo'] === grupoName);

    const thresholds = [];

    const topThresholdValue = (grupoName != 'TODOS' && produto_infos)
      ? produto_infos['Range da barra (Em gramas) -- Valor Máximo']
      : null;
    const topThreshold = topThresholdValue ? { value: (topThresholdValue as number), color: '#2D8BBA', label: 'Limite Superior', position: 'start' } : null;

    const bottomThresholdValue = (grupoName != 'TODOS' && produto_infos)
      ? produto_infos['Range da barra (Em gramas) -- Valor Mínimo']
      : null;
    const bottomThreshold = bottomThresholdValue ? { value: (bottomThresholdValue as number), color: '#2D8BBA', label: 'Limite Inferior', position: 'end' } : null;

    if (topThresholdValue == bottomThresholdValue) {
      const idealThresholdValue = topThresholdValue;
      const idealThreshold = idealThresholdValue ? { value: (idealThresholdValue as number), color: '#2D8BBA', label: 'Ideal', position: 'center' } : null;
      if (idealThreshold) thresholds.push(idealThreshold);
    }
    else {
      if (topThreshold) thresholds.push(topThreshold);
      if (bottomThreshold) thresholds.push(bottomThreshold);
    }

    const topThresholdFinalValue = (grupoName != 'TODOS' && produto_infos && produto_infos['Range da barra final (Em gramas) -- Valor Máximo'])
      ? produto_infos['Range da barra final (Em gramas) -- Valor Máximo']
      : null;
    const topThresholdFinal = topThresholdFinalValue ? { value: (topThresholdFinalValue as number), color: '#EE4B2B', label: 'Limite Superior Final', position: 'start' } : null;

    const bottomThresholdFinalValue = (grupoName != 'TODOS' && produto_infos && produto_infos['Range da barra final (Em gramas) -- Valor Mínimo'])
      ? produto_infos['Range da barra final (Em gramas) -- Valor Mínimo']
      : null;
    const bottomThresholdFinal = bottomThresholdFinalValue ? { value: (bottomThresholdFinalValue as number), color: '#EE4B2B', label: 'Limite Inferior Final', position: 'end' } : null;

    if (topThresholdFinalValue == bottomThresholdFinalValue) {
      const idealThresholdFinalValue = topThresholdFinalValue;
      const idealThresholdFinal = idealThresholdFinalValue ? { value: (idealThresholdFinalValue as number), color: '#EE4B2B', label: 'Ideal Final', position: 'center' } : null;
      if (idealThresholdFinal) thresholds.push(idealThresholdFinal);
    }
    else {
      if (topThresholdFinal) thresholds.push(topThresholdFinal);
      if (bottomThresholdFinal) thresholds.push(bottomThresholdFinal);
    }

    const ctx = document.querySelector(`main>section>div:nth-of-type(${1})>div>canvas`) as HTMLCanvasElement;
    const chart = this.chartScatterFactory(transformedDatas, ctx);
    this.charts.push(chart);

    const extendedCtx = document.querySelector(`main>section>div:nth-of-type(${1})>div:nth-of-type(2)>div>canvas`) as HTMLCanvasElement;
    const extendedChart = this.chartScatterFactory(transformedDatas, extendedCtx, thresholds);
    this.charts.push(extendedChart);

    const titleelement = document.querySelector(`main>section>div:nth-of-type(${1})>h3`) as HTMLElement;
    titleelement.innerText = 'Gráfico de Dispersão';

    const extendedtitleelement = document.querySelector(`main>section>div:nth-of-type(${1})>div:nth-of-type(2)>h3`) as HTMLElement;
    extendedtitleelement.innerText = 'Gráfico de Dispersão';

    const extendedinfoelement = document.querySelector(`main>section>div:nth-of-type(${1})>div:nth-of-type(2)>span#description`) as HTMLElement;
    extendedinfoelement.innerText = 'Cada ponto representa a média das amostras coletadas naquele período.';

    const extendedinfoselement = document.querySelector(`main>section>div:nth-of-type(${1})>div:nth-of-type(2)>div#infos`) as HTMLElement;
    extendedinfoselement.innerHTML = `
        <p><strong>OBS:</strong> Para visualizar os <i>Ranges Máximo e Mínimo</i>, ou <i>Ideal</i>, deve-se selecionar um <i>Grupo</i> no <i>filtro</i></p>
        <p><strong>OBS 2:</strong> Pode existir dois tipos de <i>Ranges</i>: <i>Ranges Máximo e Mínimo</i> e <i>Ranges Máximo e Mínimo Final</i> -- Caso os valores do <i>Máximo e Mínimo</i> forem iguais, será chamado de <i>Ideal</i></p>
    `;

    // Chart 2 - Bar Chart

    const filteredDatas2 = splitteddatas;

    const datas2 = [];
    const labels2 = [];

    const limiteinferior = (grupoName != 'TODOS' && produto_infos)
      ? produto_infos['Range da barra (Em gramas) -- Valor Mínimo']
      : 0;
    const amostras_menor_limite_inferior = filteredDatas2.filter((data: any) => parseFloat(data['AMOSTRA']) < limiteinferior);
    if (limiteinferior) datas2.push(amostras_menor_limite_inferior.length);
    if (limiteinferior) labels2.push('Amostras < ' + limiteinferior);
    const limitesuperior = (grupoName != 'TODOS' && produto_infos)
      ? produto_infos['Range da barra (Em gramas) -- Valor Máximo']
      : 100;
    const amostras_maior_limite_superior = filteredDatas2.filter((data: any) => parseFloat(data['AMOSTRA']) > limitesuperior);
    if (limitesuperior) datas2.push(amostras_maior_limite_superior.length);
    if (limitesuperior) labels2.push('Amostras > ' + limitesuperior);
    const limiteinferiorfinal = (grupoName != 'TODOS' && produto_infos)
      ? produto_infos['Range da barra final (Em gramas) -- Valor Mínimo']
      : 0;
    const amostras_menor_limite_inferior_final = filteredDatas2.filter((data: any) => parseFloat(data['PRODUTO FINAL']) < limiteinferiorfinal);
    if (limiteinferiorfinal) datas2.push(amostras_menor_limite_inferior_final.length);
    if (limiteinferiorfinal) labels2.push('Produto Finais < ' + limiteinferiorfinal);
    const limitesuperiorfinal = (grupoName != 'TODOS' && produto_infos)
      ? produto_infos['Range da barra final (Em gramas) -- Valor Máximo']
      : 100;
    const amostras_maior_limite_superior_final = filteredDatas2.filter((data: any) => parseFloat(data['PRODUTO FINAL']) > limitesuperiorfinal);
    if (limitesuperiorfinal) datas2.push(amostras_maior_limite_superior_final.length);
    if (limitesuperiorfinal) labels2.push('Produto Finais > ' + limitesuperiorfinal);

    const dataset2 = [
      {
        data: datas2,
        backgroundColor: [
          '#2D8BBA',
          '#41B8D5',
          '#EE4B2B',
          '#FF7E57'
        ],
        borderColor: [
          '#2D8BBA',
          '#41B8D5',
          '#EE4B2B',
          '#FF7E57'
        ],
      }
    ];

    const ctx2 = document.querySelector(`main>section>div:nth-of-type(${2})>div>canvas`) as HTMLCanvasElement;
    const chart2 = this.chartBarFactory(dataset2, labels2, ctx2);
    this.charts.push(chart2);

    const extendedCtx2 = document.querySelector(`main>section>div:nth-of-type(${2})>div:nth-of-type(2)>div>canvas`) as HTMLCanvasElement;
    const extendedChart2 = this.chartBarFactory(dataset2, labels2, extendedCtx2);
    this.charts.push(extendedChart2);

    const titleelement2 = document.querySelector(`main>section>div:nth-of-type(${2})>h3`) as HTMLElement;
    titleelement2.innerText = 'Casos Divergentes';

    const extendedtitleelement2 = document.querySelector(`main>section>div:nth-of-type(${2})>div:nth-of-type(2)>h3`) as HTMLElement;
    extendedtitleelement2.innerText = 'Casos Divergentes';

    const extendedinfoelement2 = document.querySelector(`main>section>div:nth-of-type(${2})>div:nth-of-type(2)>span#description`) as HTMLElement;
    extendedinfoelement2.innerText = 'Quantidade de amostras que ultrapassaram os limites estabelecidos.';

    const extendedinfoselement2 = document.querySelector(`main>section>div:nth-of-type(${2})>div:nth-of-type(2)>div#infos`) as HTMLElement;
    extendedinfoselement2.innerHTML = `
        <p><strong>OBS:</strong> Para maior assertividade nos <i>Casos Divergentes</i>, convém selecionar um <i>Grupo</i></p>
    `;

    // Chart 3 - Box Plot Chart

    const transformedDatas3 = this.transformData(splitteddatas);

    const ctx3 = document.querySelector(`main>section>div:nth-of-type(${3})>div>div`) as HTMLDivElement;
    const chart3 = this.chartBoxPlotFactory(transformedDatas3, ctx3);
    this.charts.push(chart3);

    document.querySelector(`main>section>div:nth-of-type(${3})>div:nth-of-type(2)`)?.addEventListener('toggle', (event: any) => {
      if (event.newState === "open") {
        const extendedCtx3 = document.querySelector(`main>section>div:nth-of-type(${3})>div:nth-of-type(2)>div>div`) as HTMLDivElement;
        const extendedChart3 = this.chartBoxPlotFactory(transformedDatas3, extendedCtx3);
        this.boxplotExtendedChart = extendedChart3;
      } else {
        this.boxplotExtendedChart.dispose();
      }
    });

    const titleelement3 = document.querySelector(`main>section>div:nth-of-type(${3})>h3`) as HTMLElement;
    titleelement3.innerText = 'BoxPlot';

    const extendedtitleelement3 = document.querySelector(`main>section>div:nth-of-type(${3})>div:nth-of-type(2)>h3`) as HTMLElement;
    extendedtitleelement3.innerText = 'BoxPlot';

    const extendedinfoelement3 = document.querySelector(`main>section>div:nth-of-type(${3})>div:nth-of-type(2)>span#description`) as HTMLElement;
    extendedinfoelement3.innerText = 'O gráfico BoxPlot resume a distribuição dos dados mostrando a mediana, quartis e valores atípicos, destacando a dispersão e a simetria dos dados.';

    // Chart 4 -- Worst Cases Chart

    const transformedDatas4 = this.transformData(splitteddatas);

    const ctx4 = document.querySelector(`main>section>div:nth-of-type(${4})>div>canvas`) as HTMLCanvasElement;
    const chart4 = this.chartWorstCasesFactory(transformedDatas4, ctx4);
    this.charts.push(chart4);

    const extendedCtx4 = document.querySelector(`main>section>div:nth-of-type(${4})>div:nth-of-type(2)>div>canvas`) as HTMLCanvasElement;
    const extendedChart4 = this.chartWorstCasesFactory(transformedDatas4, extendedCtx4);
    this.charts.push(extendedChart4);

    const titleelement4 = document.querySelector(`main>section>div:nth-of-type(${4})>h3`) as HTMLElement;
    titleelement4.innerText = 'Piores Casos';

    const extendedtitleelement4 = document.querySelector(`main>section>div:nth-of-type(${4})>div:nth-of-type(2)>h3`) as HTMLElement;
    extendedtitleelement4.innerText = 'Piores Casos';

    const extendedinfoelement4 = document.querySelector(`main>section>div:nth-of-type(${4})>div:nth-of-type(2)>span#description`) as HTMLElement;
    extendedinfoelement4.innerText = 'Amostras/Produtos Finais que ultrapassaram o desvio padrão.';

    // Table 5 -- Biggest Mean

    const data5 = splitteddatas;

    const ctx5 = `main>section>div:nth-of-type(${5})>div>div`;
    this.tableBiggestMeanFactory(data5, product_infos, ctx5);

    const extendedCtx5 = `main>section>div:nth-of-type(${5})>div:nth-of-type(${2})>div>div`
    this.tableBiggestMeanFactory(data5, product_infos, extendedCtx5, true);

    const titleelement5 = document.querySelector(`main>section>div:nth-of-type(${5})>h3`) as HTMLElement;
    titleelement5.innerText = 'Maiores Médias';

    const extendedtitleelement5 = document.querySelector(`main>section>div:nth-of-type(${5})>div:nth-of-type(2)>h3`) as HTMLElement;
    extendedtitleelement5.innerText = 'Maiores Médias';

    const extendedinfoelement5 = document.querySelector(`main>section>div:nth-of-type(${5})>div:nth-of-type(2)>span#description`) as HTMLElement;
    extendedinfoelement5.innerText = 'Amostras/Produtos Finais com as maiores médias.';

    // Chart 6 - Line Chart (Control Chart)

    const transformedDatas6 = this.transformData(splitteddatas);
    const biggestDataLength = Math.max(...transformedDatas6.map((data: any) => data.data.length));
    const labels6 = Array.from({ length: biggestDataLength }, (_, i) => i + 1);

    const ctx6 = document.querySelector(`main>section>div:nth-of-type(${6})>div>canvas`) as HTMLCanvasElement;
    const chart6 = this.chartLineFactory(transformedDatas6, labels6, ctx6);
    this.charts.push(chart6);

    const extendedCtx6 = document.querySelector(`main>section>div:nth-of-type(${6})>div:nth-of-type(2)>div>canvas`) as HTMLCanvasElement;
    const extendedChart6 = this.chartLineFactory(transformedDatas6, labels6, extendedCtx6);
    this.charts.push(extendedChart6);

    const titleelement6 = document.querySelector(`main>section>div:nth-of-type(${6})>h3`) as HTMLElement;
    titleelement6.innerText = 'Carta de Controle';

    const extendedtitleelement6 = document.querySelector(`main>section>div:nth-of-type(${6})>div:nth-of-type(2)>h3`) as HTMLElement;
    extendedtitleelement6.innerText = 'Carta de Controle';

    const extendedinfoelement6 = document.querySelector(`main>section>div:nth-of-type(${6})>div:nth-of-type(2)>span#description`) as HTMLElement;
    extendedinfoelement6.innerText = 'O gráfico de linha mostra a evolução das Amostras coletadas.';

    // Chart 7 - Standard Deviation Chart

    const filteredDatas7 = splitteddatas;
    const formattedDatas7 = filteredDatas7.map((data) => parseFloat(data['AMOSTRA']));
    const formattedDatas7Final = filteredDatas7.map((data) => parseFloat(data['PRODUTO FINAL']));

    const ctx7 = document.querySelector(`main>section>div:nth-of-type(${7})>div>canvas`) as HTMLCanvasElement;
    const chart7 = this.chartStandardDeviationFactory(formattedDatas7, ctx7, formattedDatas7Final, false);
    this.charts.push(chart7);

    const extendedCtx7 = document.querySelector(`main>section>div:nth-of-type(${7})>div:nth-of-type(2)>div>canvas`) as HTMLCanvasElement;
    const extendedChart7 = this.chartStandardDeviationFactory(formattedDatas7, extendedCtx7, formattedDatas7Final, true);
    this.charts.push(extendedChart7);

    const titleelement7 = document.querySelector(`main>section>div:nth-of-type(${7})>h3`) as HTMLElement;
    titleelement7.innerText = 'Desvio Padrão';

    const extendedtitleelement7 = document.querySelector(`main>section>div:nth-of-type(${7})>div:nth-of-type(2)>h3`) as HTMLElement;
    extendedtitleelement7.innerText = 'Desvio Padrão';

    const extendedinfoelement7 = document.querySelector(`main>section>div:nth-of-type(${7})>div:nth-of-type(2)>span#description`) as HTMLElement;
    extendedinfoelement7.innerText = 'O gráfico de desvio padrão mostra a dispersão dos dados em relação à média.';
  }

  chartWorstCasesFactory(data: any[], ctx: HTMLCanvasElement) {
    function calculateStandardDeviation(values: number[]): number {
      const mean = values.reduce((a, b) => a + b, 0) / values.length;
      const squaredDiffs = values.map(value => Math.pow(value - mean, 1));
      const avgSquareDiff = squaredDiffs.reduce((a, b) => a + b, 0) / values.length;
      return Math.sqrt(avgSquareDiff);
    }

    function worstCases(data: any[], deviation: number) {
      const mean = data.reduce((a, b) => a + b, 0) / data.length;
      const worstCases = data.filter((value) => Math.abs(value - mean) > deviation);
      return worstCases;
    }

    const datasets: any = [];
    for (let item of data) {
      const values = item.data.map((data: any) => parseFloat(data['AMOSTRA']));
      const valuesFinal = item.data.map((data: any) => parseFloat(data['PRODUTO FINAL']));
      const deviation = calculateStandardDeviation(values);

      const worstCasesValues = worstCases(values, deviation);
      const worstCasesValuesFinal = worstCases(valuesFinal, deviation);

      const labels = item.data.map((data: any) => {
        const dateStr = (data['Datetime de início de preenchimento'] as string).split('T')[0];
        const [year, month, day] = dateStr.split('-');

        const timeStr = (data['Datetime de início de preenchimento'] as string).split('T')[1];
        const [hours, minutes, seconds] = timeStr.split(':');

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        return new Date(formattedDate).getTime();
      });
      const worstCasesData = worstCasesValues.map((value) => ({ x: labels[values.indexOf(value)], y: value }));
      const worstCasesDataFinal = worstCasesValuesFinal.map((value) => ({ x: labels[valuesFinal.indexOf(value)], y: value }));

      datasets.push(
        {
          label: item.produto,
          data: worstCasesData,
          backgroundColor: colors[datasets.length % colors.length],
          name: 'Peso (g)'
        }
      );
      if (worstCasesDataFinal.length > 0) {
        datasets.push(
          {
            label: item.produto + ' (PRODUTO FINAL)',
            data: worstCasesDataFinal,
            backgroundColor: colors2[datasets.length % colors.length],
            name: 'Peso (g)'
          }
        );
      }
    }

    const chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: datasets
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false,
            type: 'time',
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'center',
            labels: {
              padding: 20,
            }
          }
        }
      }
    });

    return chart;
  }

  tableBiggestMeanFactory(data: any[], product_infos: any[], ctx: string, extended: boolean = false) {
    (document.querySelector(ctx) as HTMLElement).innerHTML = '';
    function getMeanByProduct(data: any[], product_infos: any[], property: string = 'AMOSTRA') {
      const products = [...new Set(data.filter((data) => data[property]).map((data) => data['Produto']))];
      if (products.length == 0) return [];
      const means = products.map((product) => {
        const filteredData = data.filter((data) => data['Produto'] == product);
        const values = filteredData.filter((data) => data[property]).map((data) => parseFloat(data[property]));
        const name = product_infos.find((info) => info['Código'] == product) ? product_infos.find((info) => info['Código'] == product)['Produto'] : 'DESCONHECIDO';
        const mean = values.reduce((a, b) => a + b, 0) / values.length;
        return { product, mean, name };
      });
      return means;
    }

    const means = getMeanByProduct(data, product_infos);
    const meansFinal = getMeanByProduct(data, product_infos, 'PRODUTO FINAL');


    const datatable = [...means.map((mean, index) => ({ "Índice": index, "Produto": mean.product, "Média": mean.mean.toFixed(2), name: mean.name })),
    ...meansFinal.map((mean, index) => ({ "Índice": index, "Produto": mean.product + ' (PRODUTO FINAL)', "Média": mean.mean.toFixed(2), name: mean.name }))];

    datatable.sort((a, b) => parseFloat(b['Média']) - parseFloat(a['Média']));

    const container = document.querySelector(ctx) as HTMLDivElement;
    datatable.forEach((data: any, index: number) => {
      const div = document.createElement('div');
      div.innerHTML = `<strong>${index + 1}</strong> - ${data['Produto']}${extended ? ' -- ' + data['name'] : ""}: <strong>${data['Média']} (g)</strong>`;
      container.appendChild(div);
    });
  }

  chartStandardDeviationFactory(datas: number[], ctx: HTMLCanvasElement, datasFinal: number[] = [], displayLegend: boolean = true) {
    function calculateStandardDeviation(values: number[]): number {
      const mean = values.reduce((a, b) => a + b, 0) / values.length;
      const squaredDiffs = values.map(value => Math.pow(value - mean, 2));
      const avgSquareDiff = squaredDiffs.reduce((a, b) => a + b, 0) / values.length;
      return Math.sqrt(avgSquareDiff);
    }

    const values = calculateStandardDeviation(datas);
    const mean = datas.reduce((a, b) => a + b, 0) / datas.length;
    const valuesFinal = calculateStandardDeviation(datasFinal);
    const meanFinal = datasFinal.reduce((a, b) => a + b, 0) / datasFinal.length;

    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: datas.map((_, index) => `Índice ${index + 1}`),
        datasets: [
          {
            label: 'Amostras',
            data: datas,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: false,
            tension: 0.1
          },
          {
            label: 'Média',
            data: new Array(datas.length).fill(mean),
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
            fill: false,
            borderDash: [5, 5]
          },
          {
            label: 'Desvio Padrão Superior',
            data: new Array(datas.length).fill(mean + values),
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
            fill: false,
            borderDash: [5, 5]
          },
          {
            label: 'Desvio Padrão Inferior',
            data: new Array(datas.length).fill(mean - values),
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
            fill: false,
            borderDash: [5, 5]
          },
          {
            label: 'Produtos Finais',
            data: datasFinal,
            borderColor: 'rgba(64, 130, 109, 1)',
            backgroundColor: 'rgba(64, 130, 109, 0.2)',
            fill: false,
            tension: 0.1
          },
          {
            label: 'Média Produto Finais',
            data: new Array(datasFinal.length).fill(meanFinal),
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
            fill: false,
            borderDash: [5, 5]
          },
          {
            label: 'Desvio Padrão Superior Produto Finais',
            data: new Array(datasFinal.length).fill(meanFinal + valuesFinal),
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
            fill: false,
            borderDash: [5, 5]
          },
          {
            label: 'Desvio Padrão Inferior Produto Finais',
            data: new Array(datasFinal.length).fill(meanFinal - valuesFinal),
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1,
            fill: false,
            borderDash: [5, 5]
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false
          }
        },
        plugins: {
          legend: {
            display: displayLegend,
          }
        }
      }
    });

    return chart;
  }

  chartBoxPlotFactory(datas: any[], ctx: HTMLDivElement) {
    const chart = echarts.init(ctx);
    const option = {
      dataset: [
        {
          source: [...datas.map((item) => item.data.map((infos: any) => parseFloat(infos['AMOSTRA']))), ...(datas.every((item) => item.data.every((infos: any) => 'PRODUTO FINAL' in infos)) ? datas.map((item) => item.data.map((infos: any) => parseFloat(infos['PRODUTO FINAL']))) : []),]
        },
        {
          transform: {
            type: 'boxplot',
            config: {}
          }
        }
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params: any) {
          const index = params.dataIndex;
          const itemName = [...datas.map((data) => data.produto), ...(datas.every((item) => item.data.every((infos: any) => 'PRODUTO FINAL' in infos)) ? datas.map((data) => data.produto + " (PRODUTO FINAL)") : [])][index] || 'N/A';
          const values = [];
          for (let value of params.value) {
            if (typeof value == 'number') values.push(" " + value.toFixed(2) + " (g)");
            else values.push(" Índice " + (parseInt(value) + 1));
          }
          return `${itemName}: ${values}`;
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        nameGap: 30,
        splitArea: {
          show: false
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: function (value: number) {
            return [...datas.map((data) => data.produto), ...(datas.every((item) => item.data.every((infos: any) => 'PRODUTO FINAL' in infos)) ? datas.map((data) => data.produto + " (PRODUTO FINAL)") : [])][value] || 'N/A';
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Value',
        splitArea: {
          show: true
        },
        min: 'dataMin',
        max: 'dataMax',
        axisLabel: {
          formatter: function (value: number) {
            return value.toFixed(2);
          }
        }
      },
      series: [
        {
          name: 'boxplot',
          type: 'boxplot',
          datasetIndex: 1
        }
      ]
    };
    chart.setOption(option);

    window.addEventListener('resize', () => chart.resize());

    return chart;
  }

  chartLineFactory(data: any[], labels: number[], ctx: HTMLCanvasElement) {
    const datasets: any[] = [];

    for (let item of data) {
      const values = item.data.map((data: any) => parseFloat(data['AMOSTRA']));
      datasets.push({
        label: item.produto,
        data: values,
        borderColor: colors[datasets.length % colors.length],
        backgroundColor: colors[datasets.length % colors.length],
      });
      const valuesFinal = item.data.map((data: any) => data['PRODUTO FINAL'] ? parseFloat(data['PRODUTO FINAL']) : null).filter((data: any) => data);
      if (valuesFinal.length > 0)
        datasets.push({
          label: item.produto + ' (PRODUTO FINAL)',
          data: valuesFinal,
          borderColor: colors2[datasets.length % colors.length],
          backgroundColor: colors2[datasets.length % colors.length],
        });
    }

    const plugins = {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          padding: 20,
        }
      },
    };

    const chartOption: any = {
      maintainAspectRatio: false,
      scales: {
        x: {
          display: false,
          type: 'category',
        },
        y: {
          title: {
            display: true,
            text: 'Peso (em gramas)'
          }
        }
      },
      plugins: plugins,
    };

    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: chartOption
    });

    return chart;
  }

  chartBarFactory(datasets: any[], labels: string[], ctx: HTMLCanvasElement) {
    const options: any = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: 'left',
          align: 'center',
          labels: {
            padding: 20,
          }
        },
      }
    };

    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: options
    });

    return chart;
  }

  chartScatterFactory(datas: any[], ctx: HTMLCanvasElement, thresholds: { value: number, color: string, label: string, position: string }[] | null = null) {

    const parseDateString = (dateString: string) => {
      const [datePart, timePart] = dateString.split('T');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);
      return new Date(year, month - 1, day, hours, minutes, seconds);
    };

    const datasets: any[] = [];

    for (let item of datas) {
      const labels = item.data.map((data: any) => (data['Datetime de início de preenchimento'] as string));
      const values = item.data.map((data: any, i: number) => ({ x: parseDateString(labels[i]), y: data['AMOSTRA'] }));
      const valuesfinais = item.data.map((data: any, i: number) => ({ x: parseDateString(labels[i]), y: data['PRODUTO FINAL'] })).map((data: any) => data.y ? data : null).filter((data: any) => data);
      datasets.push({
        data: values,
        borderColor: colors[datasets.length % colors.length],
        backgroundColor: colors[datasets.length % colors.length],
        label: item.produto,
      });
      if (valuesfinais.length > 0)
        datasets.push({
          data: valuesfinais,
          borderColor: colors2[datasets.length % colors.length],
          backgroundColor: colors2[datasets.length % colors.length],
          label: item.produto + ' (PRODUTO FINAL)',
        });
    }

    let annotations: any = {};

    let index = 0;
    for (let threshold of thresholds || []) {
      index++;
      annotations['annotation' + index] = {
        type: 'line',
        yMin: threshold.value,
        yMax: threshold.value,
        borderColor: threshold.color,
        borderWidth: 2,
        borderDash: [10, 5],
        label: {
          content: threshold.label,
          display: true,
          backgroundColor: threshold.color,
          font: {
            size: 10,
          },
          position: threshold.position
        }
      }
    }

    const plugins = {
      legend: {
        display: true,
        position: 'bottom',
        align: 'center',
        labels: {
          padding: 20,
        }
      },
      annotation: { annotations }
    };

    const chartOption: any = {
      maintainAspectRatio: false,
      scales: {
        x: {
          display: false,
          type: 'time',
        }
      },
      plugins: plugins,
    };

    const chart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: datasets
      },
      options: chartOption
    });

    return chart;
  }

  addTooltips() {
    function tooltipMouseOverHowDoMultipleSelection(event: any, div: HTMLDivElement, innerContent: string) {
      const tooltip = div;
      tooltip.style.top = event.pageY + 'px';
      tooltip.style.left = event.pageX + 'px';
      tooltip.innerHTML = innerContent;
      tooltip.style.display = 'flex';
    }
    function tooltipMouseOutHowDoMultipleSelection(div: HTMLDivElement) {
      const tooltip = div;
      tooltip.style.display = 'none';
    }

    const tooltipDiv = document.querySelector('main>div.tooltip') as HTMLDivElement;
    
    const whySelectGrupo = document.querySelector('#whySelectGrupo') as HTMLElement;
    whySelectGrupo.addEventListener('mouseover', e => tooltipMouseOverHowDoMultipleSelection(e, tooltipDiv, `<span><strong>Por que selecionar <i>Grupo</i>?</strong> A seleção do <i>Grupo</i> influencia na assetividade da leitura do <i>Gráfico de Dispersão</i> e do gráfico de <i>Casos Divergentes</i>. Isso ocorre, pois, a seleção do <i>Grupo</i> traz informações, como, <i>Ranges Máximos e Mínimos</i>, auxiliando no entendimento da qualidade dos dados.</span>`));
    whySelectGrupo.addEventListener('mouseout', e => tooltipMouseOutHowDoMultipleSelection(tooltipDiv));
  }

  ngOnInit() {
    Chart.register(...registerables, annotationPlugin);

    this.ifLogged();

    this.addTooltips();

    
  }

  async ifLogged() {
    this.isLoading = true;
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        this.token = await user.getIdToken();
        this.getDatas();
      }
      else {
        window.location.href = '/';
      }
    });
  }

  async logout() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    await auth.signOut();
    window.location.href = '/';
  }
}

const colors = [
  '#2D8BBA',
  '#41B8D5',
  '#87CEEB',
  '#0047AB',
  '#CCCCFF'
]

const colors2 = [
  '#FF0000',
  '#FF4500',
  '#FFA500',
  '#FFD700',
  '#FFFF00'
]

const firebaseConfig = {
  apiKey: "AIzaSyBYeEfjL_dbgN-CKeyWqLNW1IunFxHmjxs",
  authDomain: "ideia-tech.firebaseapp.com",
  projectId: "ideia-tech",
  storageBucket: "ideia-tech.appspot.com",
  messagingSenderId: "818775631889",
  appId: "1:818775631889:web:231d6a249422230d5029b0"
};