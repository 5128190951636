<nav *ngIf="!isLogInPage">
    <img src="logotipo_branco.png">
    
    <section>
        <p>Acesso rápido</p>
        <ul>
            <li>
                <a href="/home" [class]="{ 'active': router.url === '/home' }">
                    <i class="fa-solid fa-house"></i>

                    <span>Home</span>
                </a>
            </li>
        </ul>
    </section>

    <section>
        <p>Controles</p>
        <ul>
            <li>
                <a href="/controle-qualidade" [class]="{ 'active': router.url === '/controle-qualidade' }">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3532_644)">
                            <path
                                d="M15.5832 13.9745V20.2738C15.5832 20.3549 15.5617 20.4346 15.5209 20.5046C15.48 20.5747 15.4214 20.6327 15.3508 20.6727C15.2803 20.7126 15.2004 20.7332 15.1193 20.7322C15.0382 20.7312 14.9588 20.7087 14.8893 20.6671L10.9998 18.3332L7.11042 20.6671C7.04079 20.7088 6.96133 20.7313 6.88017 20.7322C6.799 20.7332 6.71904 20.7125 6.64846 20.6724C6.57788 20.6323 6.51921 20.5742 6.47846 20.504C6.43771 20.4338 6.41632 20.3541 6.4165 20.2729V13.9754C5.23047 13.0258 4.36866 11.7313 3.95017 10.2707C3.53169 8.81016 3.57718 7.25567 4.08037 5.82208C4.58356 4.38849 5.5196 3.14658 6.75914 2.26798C7.99867 1.38939 9.4805 0.91748 10.9998 0.91748C12.5192 0.91748 14.001 1.38939 15.2405 2.26798C16.4801 3.14658 17.4161 4.38849 17.9193 5.82208C18.4225 7.25567 18.468 8.81016 18.0495 10.2707C17.631 11.7313 16.7692 13.0258 15.5832 13.9754V13.9745ZM8.24984 15.0497V17.8456L10.9998 16.1956L13.7498 17.8456V15.0497C12.8761 15.4031 11.9423 15.5843 10.9998 15.5832C10.0574 15.5843 9.12356 15.4031 8.24984 15.0497ZM10.9998 13.7499C12.4585 13.7499 13.8575 13.1704 14.8889 12.139C15.9204 11.1075 16.4998 9.70859 16.4998 8.2499C16.4998 6.7912 15.9204 5.39226 14.8889 4.36081C13.8575 3.32936 12.4585 2.7499 10.9998 2.7499C9.54115 2.7499 8.1422 3.32936 7.11075 4.36081C6.0793 5.39226 5.49984 6.7912 5.49984 8.2499C5.49984 9.70859 6.0793 11.1075 7.11075 12.139C8.1422 13.1704 9.54115 13.7499 10.9998 13.7499Z"
                                fill="#23272E" />
                            <circle cx="10.9998" cy="8.11304" r="3.62622" fill="#23272E" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3532_644">
                                <rect width="22" height="22" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span>Qualidade</span>
                </a>
            </li>
            <li>
                <a href="/reprocessos" [class]="{ 'active': router.url === '/reprocessos' }">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3544_1299)">
                            <path
                                d="M27.8055 29.3505C25.0837 31.709 21.6015 33.005 18 33C9.7155 33 3 26.2845 3 18C3 9.7155 9.7155 3 18 3C26.2845 3 33 9.7155 33 18C33 21.204 31.995 24.174 30.285 26.61L25.5 18H30C29.9998 15.2343 29.0442 12.5536 27.295 10.4113C25.5458 8.26908 23.1103 6.79679 20.4005 6.24354C17.6907 5.69028 14.873 6.09003 12.424 7.37515C9.97504 8.66027 8.04513 10.7519 6.96079 13.2961C5.87644 15.8404 5.70421 18.6811 6.47324 21.3377C7.24227 23.9943 8.90534 26.3038 11.1811 27.8753C13.4569 29.4469 16.2057 30.1841 18.9625 29.9623C21.7192 29.7405 24.3148 28.5732 26.31 26.658L27.8055 29.3505Z"
                                fill="#23272E" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3544_1299">
                                <rect width="36" height="36" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <span>Reprocessos</span>
                </a>
            </li>
        </ul>
    </section>

    <section>
        <p>Painéis de acompanhamento</p>
        <ul>
            <li>
                <a href="/insights" [class]="{ 'active': router.url === '/insights' }">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.14194 16.5002H12.8581C12.9791 15.3984 13.541 14.4891 14.4531 13.4963C14.5567 13.3845 15.2158 12.7016 15.2937 12.6044C15.9411 11.7955 16.3469 10.8203 16.4644 9.79093C16.5818 8.76157 16.4062 7.71996 15.9576 6.78606C15.509 5.85217 14.8058 5.06396 13.9289 4.51222C13.052 3.96047 12.0371 3.66763 11.001 3.6674C9.96497 3.66718 8.94994 3.95959 8.0728 4.51097C7.19566 5.06234 6.49209 5.85024 6.04311 6.78395C5.59414 7.71765 5.41802 8.75918 5.53503 9.7886C5.65205 10.818 6.05744 11.7934 6.70452 12.6026C6.78336 12.7006 7.44427 13.3845 7.54602 13.4954C8.45902 14.4891 9.02094 15.3984 9.14194 16.5002ZM12.8334 18.3336H9.16669V19.2502H12.8334V18.3336ZM5.27452 13.7502C4.4112 12.6716 3.87014 11.371 3.71368 9.99827C3.55722 8.62557 3.79172 7.23658 4.39017 5.99132C4.98862 4.74606 5.92667 3.69519 7.09627 2.95976C8.26586 2.22432 9.61942 1.83425 11.001 1.83447C12.3826 1.83469 13.736 2.2252 14.9054 2.96101C16.0748 3.69681 17.0125 4.74799 17.6105 5.99344C18.2086 7.23889 18.4426 8.62795 18.2857 10.0006C18.1288 11.3733 17.5874 12.6737 16.7237 13.7521C16.1554 14.4597 14.6667 15.5836 14.6667 16.9586V19.2502C14.6667 19.7365 14.4735 20.2028 14.1297 20.5466C13.7859 20.8904 13.3196 21.0836 12.8334 21.0836H9.16669C8.68046 21.0836 8.21415 20.8904 7.87033 20.5466C7.52651 20.2028 7.33336 19.7365 7.33336 19.2502V16.9586C7.33336 15.5836 5.84377 14.4597 5.27452 13.7502ZM11.9167 9.17055H14.2084L10.0834 14.6706V11.0039H7.79169L11.9167 5.50022V9.17147V9.17055Z"
                            fill="white" />
                    </svg>

                    <span>Insights</span>
                </a>
            </li>
        </ul>
    </section>

    <section>
        <p>Geral</p>
        <ul>
            <li>
                <a href="/perfil" [class]="{ 'active': router.url === '/perfil' }">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.0002 19.1668C4.93741 19.1668 0.833496 15.0629 0.833496 10.0002C0.833496 4.93741 4.93741 0.833496 10.0002 0.833496C15.0629 0.833496 19.1668 4.93741 19.1668 10.0002C19.1668 15.0629 15.0629 19.1668 10.0002 19.1668ZM5.42875 15.7348C6.72633 16.7722 8.33887 17.3361 10.0002 17.3335C11.806 17.3335 13.4587 16.6808 14.7366 15.5992C14.1396 14.9866 13.4258 14.5 12.6374 14.1681C11.8491 13.8362 11.0022 13.6657 10.1468 13.6668C9.26003 13.6658 8.38271 13.8491 7.57051 14.2051C6.75831 14.5611 6.02896 15.082 5.42875 15.7348ZM4.14816 14.4185C4.91858 13.6009 5.84828 12.9497 6.87996 12.5052C7.91164 12.0606 9.02344 11.832 10.1468 11.8335C11.23 11.8321 12.3028 12.0447 13.3036 12.459C14.3044 12.8734 15.2135 13.4813 15.9787 14.248C16.7634 13.1435 17.2268 11.8432 17.3175 10.4914C17.4082 9.13959 17.1226 7.78906 16.4924 6.58966C15.8623 5.39027 14.9121 4.3889 13.7475 3.69665C12.5828 3.00441 11.2491 2.64835 9.89438 2.66798C8.53966 2.68761 7.21685 3.08216 6.07272 3.80786C4.92859 4.53356 4.00788 5.56203 3.41271 6.77918C2.81755 7.99633 2.57122 9.35456 2.70104 10.7032C2.83086 12.0518 3.33175 13.3381 4.14816 14.4194V14.4185ZM10.0002 10.9168C9.0277 10.9168 8.09507 10.5305 7.40744 9.84289C6.7198 9.15525 6.3335 8.22262 6.3335 7.25016C6.3335 6.2777 6.7198 5.34507 7.40744 4.65744C8.09507 3.9698 9.0277 3.5835 10.0002 3.5835C10.9726 3.5835 11.9053 3.9698 12.5929 4.65744C13.2805 5.34507 13.6668 6.2777 13.6668 7.25016C13.6668 8.22262 13.2805 9.15525 12.5929 9.84289C11.9053 10.5305 10.9726 10.9168 10.0002 10.9168ZM10.0002 9.0835C10.4864 9.0835 10.9527 8.89034 11.2965 8.54653C11.6403 8.20271 11.8335 7.73639 11.8335 7.25016C11.8335 6.76393 11.6403 6.29762 11.2965 5.9538C10.9527 5.60998 10.4864 5.41683 10.0002 5.41683C9.51393 5.41683 9.04762 5.60998 8.7038 5.9538C8.35998 6.29762 8.16683 6.76393 8.16683 7.25016C8.16683 7.73639 8.35998 8.20271 8.7038 8.54653C9.04762 8.89034 9.51393 9.0835 10.0002 9.0835Z"
                            fill="#6F7480" />
                    </svg>

                    <span>Minha conta</span>
                </a>
            </li>
            <li>
                <button (click)="logOut()">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.3741 14.6703L5.4834 6.78054L6.77957 5.48438L14.6693 13.3732V6.42029H16.5026V16.5036H6.41932V14.6703H13.3741Z"
                            fill="white" />
                    </svg>

                    <span>Sair</span>
                </button>
            </li>
        </ul>
    </section>
</nav>