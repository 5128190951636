<main>
    <h1>
        <span>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3532_644)">
                    <path
                        d="M15.5832 13.9745V20.2738C15.5832 20.3549 15.5617 20.4346 15.5209 20.5046C15.48 20.5747 15.4214 20.6327 15.3508 20.6727C15.2803 20.7126 15.2004 20.7332 15.1193 20.7322C15.0382 20.7312 14.9588 20.7087 14.8893 20.6671L10.9998 18.3332L7.11042 20.6671C7.04079 20.7088 6.96133 20.7313 6.88017 20.7322C6.799 20.7332 6.71904 20.7125 6.64846 20.6724C6.57788 20.6323 6.51921 20.5742 6.47846 20.504C6.43771 20.4338 6.41632 20.3541 6.4165 20.2729V13.9754C5.23047 13.0258 4.36866 11.7313 3.95017 10.2707C3.53169 8.81016 3.57718 7.25567 4.08037 5.82208C4.58356 4.38849 5.5196 3.14658 6.75914 2.26798C7.99867 1.38939 9.4805 0.91748 10.9998 0.91748C12.5192 0.91748 14.001 1.38939 15.2405 2.26798C16.4801 3.14658 17.4161 4.38849 17.9193 5.82208C18.4225 7.25567 18.468 8.81016 18.0495 10.2707C17.631 11.7313 16.7692 13.0258 15.5832 13.9754V13.9745ZM8.24984 15.0497V17.8456L10.9998 16.1956L13.7498 17.8456V15.0497C12.8761 15.4031 11.9423 15.5843 10.9998 15.5832C10.0574 15.5843 9.12356 15.4031 8.24984 15.0497ZM10.9998 13.7499C12.4585 13.7499 13.8575 13.1704 14.8889 12.139C15.9204 11.1075 16.4998 9.70859 16.4998 8.2499C16.4998 6.7912 15.9204 5.39226 14.8889 4.36081C13.8575 3.32936 12.4585 2.7499 10.9998 2.7499C9.54115 2.7499 8.1422 3.32936 7.11075 4.36081C6.0793 5.39226 5.49984 6.7912 5.49984 8.2499C5.49984 9.70859 6.0793 11.1075 7.11075 12.139C8.1422 13.1704 9.54115 13.7499 10.9998 13.7499Z"
                        fill="#23272E" />
                    <circle cx="10.9998" cy="8.11304" r="3.62622" fill="#23272E" />
                </g>
                <defs>
                    <clipPath id="clip0_3532_644">
                        <rect width="22" height="22" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            &nbsp;
            Controle de Qualidade
        </span>
        <quick-access-buttons></quick-access-buttons>
    </h1>

    <section class="dados">

        <div>
            <p>Adicione novas medições aqui</p>
            <button popovertarget="drafts">Adicionar</button>
        </div>

        <div>
            <input type="text" placeholder="Pesquisar (Por LOTE ou Data de Preenchimento)" (input)="onSearchChange($event)">
        </div>

        <ul>
            <li>
                <div>
                    <span>DATA DE PREENCHIMENTO</span>
                    <span>CATEGORIA</span>
                    <span>PRODUTO</span>
                    <span>LOTE</span>
                    <span>NOME RESPONSÁVEL</span>
                    <span>E-MAIL</span>
                    <span>VALIDO ATÉ</span>
                    <button disabled><i class="fa-solid fa-eye"></i></button>
                </div>
            </li>
        </ul>

        <ul [attr.aria-disabled]="isLoading">
            <p *ngIf="isLoading">Carregando...</p>
            <p *ngIf="!isLoading && filteredDatas.length == 0">Nenhum dado encontrado</p>
            <li *ngFor="let data of filteredDatas">
                <div>
                    <span>{{ formatDate(data['Datetime de início de preenchimento']) }}</span>
                    <span>{{ data['Categoria'] }}</span>
                    <span>{{ data['Produto'] }}</span>
                    <span>{{ data['LOTE'] }}</span>
                    <span>{{ data['Nome do(a) responsável'] }}</span>
                    <span>{{ data['Email'] }}</span>
                    <span>VALIDO ATÉ: {{ data['VALIDADE'] }}</span>
                    <button (click)="showData(data)"><i class="fa-solid fa-eye"></i></button>
                </div>
            </li>
        </ul>
    </section>
</main>

<div id="view-data" class="view-data" popover>

    <h1>REG053 - Liberação de produto</h1>

    <h2 id="sucess-to-add-data"></h2>

    <form>
        <label class="Datetime de início de preenchimento">
            <span>Data de Preenchimento</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Email">
            <span>E-mail</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Nome do(a) responsável">
            <span>Nome do(a) Responsável</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Produto">
            <span>Produto</span>
            <input type="text" value="" disabled>
        </label>

        <label class="LOTE">
            <span>LOTE</span>
            <input type="text" value="" disabled>
        </label>

        <div class="group">

            <label class="Aparência">
                <span>Aparência</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Odor">
                <span>Odor</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Sabor">
                <span>Sabor</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Textura">
                <span>Textura</span>
                <input type="text" value="" disabled>
            </label>

        </div>

        <label class="Houve alguma NÃO CONFORMIDADE na análise sensorial?">
            <span>Houve alguma NÃO CONFORMIDADE na análise sensorial</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Qual a causa da não conformidade?">
            <span>Qual a causa da não conformidade?</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Fotos em caso de não conformidade visual.">
            <span>Foto em caso de não conformidade visual.</span>
            <a href=""></a>
        </label>

        <label class="Categoria">
            <span>Categoria</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Passa pela laminação?">
            <span>Passa pela laminação?</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Horário - Do produto acabado">
            <span>Horário - Do produto acabado</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Carrinho">
            <span>Carrinho</span>
            <input type="text" value="" disabled>
        </label>

        <p>Amostras coletadas de 1 a 10</p>

        <div class="group-indented">

            <label class="Range de Peso da Placa - Mínimo (em gramas)">
                <span>Range de Peso da Placa - Mínimo (em gramas)</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Range de Peso da Placa - Máximo (em gramas)">
                <span>Range de Peso da Placa - Máximo (em gramas)</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Peso da Placa 1 (em gramas)">
                <span>Peso da Placa 1 (em gramas)</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Peso da Placa 2 (em gramas)">
                <span>Peso da Placa 2 (em gramas)</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Range de Peso do Produto - Mínimo (em gramas)">
                <span>Range de Peso do Produto - Mínimo (em gramas)</span>
                <input type="text" value="" disabled>
            </label>

            <label class="Range de Peso do Produto - Máximo (em gramas)">
                <span>Range de Peso do Produto - Máximo (em gramas)</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 1">
                <span>Amostra 1</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 2">
                <span>Amostra 2</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 3">
                <span>Amostra 3</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 4">
                <span>Amostra 4</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 5">
                <span>Amostra 5</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 6">
                <span>Amostra 6</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 7">
                <span>Amostra 7</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 8">
                <span>Amostra 8</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 9">
                <span>Amostra 9</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 10">
                <span>Amostra 10</span>
                <input type="text" value="" disabled>
            </label>

        </div>
        
        <label class="O produto é Pinati?">
            <span>O produto é Pinati?</span>
            <input type="text" value="" disabled>
        </label>

        <p>Amostras coletadas (extras) de 11 a 15</p>

        <div class="group-indented">

            <label class="AMOSTRA 11">
                <span>Amostra 11</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 12">
                <span>Amostra 12</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 13">
                <span>Amostra 13</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 14">
                <span>Amostra 14</span>
                <input type="text" value="" disabled>
            </label>

            <label class="AMOSTRA 15">
                <span>Amostra 15</span>
                <input type="text" value="" disabled>
            </label>

        </div>

        <label class="O produto possui cobertura de chocolate?">
            <span>O produto possui cobertura de chocolate?</span>
            <input type="text" value="" disabled>
        </label>

        <p>Amostras coletadas de produto final de 1 a 10</p>

        <div class="group-indented">

            <label class="PRODUTO FINAL - AMOSTRA 1">
                <span>Produto Final - Amostra 1</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 2">
                <span>Produto Final - Amostra 2</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 3">
                <span>Produto Final - Amostra 3</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 4">
                <span>Produto Final - Amostra 4</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 5">
                <span>Produto Final - Amostra 5</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 6">
                <span>Produto Final - Amostra 6</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 7">
                <span>Produto Final - Amostra 7</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 8">
                <span>Produto Final - Amostra 8</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 9">
                <span>Produto Final - Amostra 9</span>
                <input type="text" value="" disabled>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 10">
                <span>Produto Final - Amostra 10</span>
                <input type="text" value="" disabled>
            </label>

        </div>

        <label class="A etiqueta está conforme?">
            <span>A etiqueta está conforme?</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Foto da etiqueta">
            <span>Foto da etiqueta</span>
            <a href=""></a>
        </label>

        <label class="Foto da etiqueta 2">
            <span>Foto da etiqueta 2</span>
            <a href=""></a>
        </label>

        <label class="VALIDADE">
            <span>Valido até</span>
            <input type="text" value="" disabled>
        </label>

        <label class="EMBALAGEM">
            <span>Embalagem</span>
            <input type="text" value="" disabled>
        </label>

        <label class="EMBALAGEM SECUNDÁRIA">
            <span>Embalagem Secundária</span>
            <input type="text" value="" disabled>
        </label>

        <label class="EQUIPAMENTO">
            <span>Equipamento</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Compatibilidade de lote e validade">
            <span>Compatibilidade de lote e validade</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Horário - De envio">
            <span>Horário - De envio</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Itens conformes">
            <span>Itens conformes</span>
            <p class="padded"></p>
        </label>

        <label class="Houve algum item não conforme?">
            <span>Houve algum item não conforme?</span>
            <input type="text" value="" disabled>
        </label>

        <label class="DE ACORDO?">
            <span>De acordo?</span>
            <input type="text" value="" disabled>
        </label>

        <label class="Documento verificado e liberado pelo responsável?">
            <span>Documento verificado e liberado pelo responsável?</span>
            <input type="text" value="" disabled>
        </label>
    </form>

    <div class="buttons">
        <button (click)="toPrintData()">Salvar cópia</button>
        <button class="outlined" popoverTarget="view-data">Fechar</button>
    </div>
</div>

<div id="drafts" class="drafts" popover>
    <p class="black">Adicione uma nova medição agora mesmo</p>
    <ul [attr.aria-disabled]="isLoading">
        <p *ngIf="isLoading">Carregando...</p>
        <p *ngIf="!isLoading && drafts.length == 0">Nenhum rascunho encontrado</p>
        <li *ngFor="let draft of drafts">
            <div>
                <span>{{ formatDate(draft['Datetime de início de preenchimento']) }} - {{ draft['Produto'] !== '' && draft['Produto'] !== null ? draft['Produto'] : 'Sem produto' }}</span>
                <button (click)="this.openAddDataPopoverDraft(draft)">Continuar</button>
            </div>
        </li>
    </ul>
    <div class="buttons">
        <button (click)="this.openAddDataPopover()">Nova medição</button>
        <button class="outlined" popovertarget="drafts">Fechar</button>
    </div>
</div>

<div id="add-data" class="add-data" popover>

    <h1>REG053 - Liberação de produto</h1>

    <p class="black"></p>

    <form>

        <label class="Datetime de início de preenchimento">
            <span>Início do Preenchimento</span>
            <input type="text" value="{{ formatDate(addData['Datetime de início de preenchimento']) }}" disabled>
        </label>

        <label class="Nome do(a) responsável">
            <span>Nome do(a) Responsável</span>
            <input type="text" value="" list="nome_sugestoes" (input)="onInputChange($event)">
            <datalist id="nome_sugestoes">
                <option value="Jéssica"></option>
                <option value="Katiane"></option>
                <option value="Mariana"></option>
                <option value="Stefány"></option>
            </datalist>
            <p>* Obrigatório</p>
        </label>

        <label class="Produto">
            <span>Produto</span>
            <input (input)="onInputChange($event)" list="produto_sugestoes" placeholder="Código do produto">
            <datalist id="produto_sugestoes">
                <option *ngFor="let produto of produtosSelect" value="{{ produto.codigo }}">{{ produto.codigo }} ({{
                    produto.nome }})</option>
            </datalist>
            <p>* Obrigatório</p>
        </label>

        <label class="LOTE">
            <span>LOTE</span>
            <input type="text" value="" (input)="onInputChange($event)">
            <p>* Obrigatório</p>
        </label>

        <div class="group">

            <label class="Aparência">
                <span>Aparência</span>
                <select (input)="onInputChange($event)">
                    <option value="">Selecione</option>
                    <option value="true">Conforme</option>
                    <option value="false">Não Conforme</option>
                </select>
                <p>* Obrigatório</p>
            </label>

            <label class="Odor">
                <span>Odor</span>
                <select (input)="onInputChange($event)">
                    <option value="">Selecione</option>
                    <option value="true">Conforme</option>
                    <option value="false">Não Conforme</option>
                </select>
                <p>* Obrigatório</p>
            </label>

            <label class="Sabor">
                <span>Sabor</span>
                <select (input)="onInputChange($event)">
                    <option value="">Selecione</option>
                    <option value="true">Conforme</option>
                    <option value="false">Não Conforme</option>
                </select>
                <p>* Obrigatório</p>
            </label>

            <label class="Textura">
                <span>Textura</span>
                <select (input)="onInputChange($event)">
                    <option value="">Selecione</option>
                    <option value="true">Conforme</option>
                    <option value="false">Não Conforme</option>
                </select>
                <p>* Obrigatório</p>
            </label>

        </div>

        <label class="Houve alguma NÃO CONFORMIDADE na análise sensorial?">
            <span>Houve alguma NÃO CONFORMIDADE na análise sensorial?</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="Qual a causa da não conformidade?" [style.display]="addData['Houve alguma NÃO CONFORMIDADE na análise sensorial?'] === true ? 'block' : 'none'">
            <span>Qual a causa da não conformidade?</span>
            <input type="text" value="" (input)="onInputChange($event)">
            <p>* Obrigatório</p>
        </label>

        <label class="Fotos em caso de não conformidade visual." [style.display]="addData['Houve alguma NÃO CONFORMIDADE na análise sensorial?'] === true ? 'block' : 'none'">
            <span>Foto em caso de não conformidade visual.</span>
            <input type="file" value="" (input)="onInputFileChange($event)" accept="image/*">
            <p></p>
        </label>

        <label class="Categoria">
            <span>Categoria</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option *ngFor="let categoria of categoriasSelect" [value]="categoria">{{ categoria }}</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="Passa pela laminação?" [style.display]="['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">
            <span>Passa pela laminação?</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="Horário - Do produto acabado" [style.display]="addData['Passa pela laminação?'] === true && ['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">
            <span>Horário - Do produto acabado</span>
            <input type="time" value="" (input)="onInputChange($event)">
            <p>* Obrigatório</p>
        </label>

        <label class="Carrinho" [style.display]="addData['Passa pela laminação?'] === true && ['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">
            <span>Carrinho</span>
            <input type="number" value="" min="0" (input)="onInputChange($event)">
            <p>* Obrigatório</p>
        </label>

        <p class="keep" [style.display]="['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">
            Amostras coletadas de 1 a 10
        </p>

        <div class="group-indented" [style.display]="['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">

            <label class="Range de Peso da Placa - Mínimo (em gramas)" [style.display]="addData['Passa pela laminação?'] === true ? 'block' : 'none'">
                <span>Range de Peso da Placa - Mínimo (em gramas)</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="Range de Peso da Placa - Máximo (em gramas)" [style.display]="addData['Passa pela laminação?'] === true ? 'block' : 'none'">
                <span>Range de Peso da Placa - Máximo (em gramas)</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="Peso da Placa 1 (em gramas)" [style.display]="addData['Passa pela laminação?'] === true ? 'block' : 'none'">
                <span>Peso da Placa 1 (em gramas)</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="Peso da Placa 2 (em gramas)" [style.display]="addData['Passa pela laminação?'] === true ? 'block' : 'none'">
                <span>Peso da Placa 2 (em gramas)</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="Range de Peso do Produto - Mínimo (em gramas)">
                <span>Range de Peso do Produto - Mínimo (em gramas)</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="Range de Peso do Produto - Máximo (em gramas)">
                <span>Range de Peso do Produto - Máximo (em gramas)</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 1">
                <span>Amostra 1</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 2">
                <span>Amostra 2</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 3">
                <span>Amostra 3</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 4">
                <span>Amostra 4</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 5">
                <span>Amostra 5</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 6">
                <span>Amostra 6</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 7">
                <span>Amostra 7</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 8">
                <span>Amostra 8</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 9">
                <span>Amostra 9</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 10">
                <span>Amostra 10</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

        </div>

        <label class="O produto é Pinati?" [style.display]="addData['Passa pela laminação?'] === true && ['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">
            <span>O produto é Pinati?</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <p class="keep" [style.display]="addData['O produto é Pinati?'] === true && ['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">
            Amostras coletadas (extras) de 11 a 15
        </p>

        <div class="group-indented" [style.display]="addData['O produto é Pinati?'] === true && ['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">

            <label class="AMOSTRA 11">
                <span>Amostra 11</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 12">
                <span>Amostra 12</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 13">
                <span>Amostra 13</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 14">
                <span>Amostra 14</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="AMOSTRA 15">
                <span>Amostra 15</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

        </div>

        <label class="O produto possui cobertura de chocolate?" [style.display]="['Barra', 'Doces'].includes(addData['Categoria']) ? 'block' : 'none'">
            <span>O produto possui cobertura de chocolate?</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <p class="keep" [style.display]="addData['O produto possui cobertura de chocolate?'] === true || ['Biscoitos', 'Chocolates', 'Drageados'].includes(addData['Categoria']) ? 'block' : 'none'">
            Amostras coletadas, de produto final, de 1 a 10
        </p>

        <div class="group-indented" [style.display]="addData['O produto possui cobertura de chocolate?'] === true || ['Biscoitos', 'Chocolates', 'Drageados'].includes(addData['Categoria']) ? 'block' : 'none'">

            <label class="PRODUTO FINAL - AMOSTRA 1">
                <span>Produto Final - Amostra 1</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 2">
                <span>Produto Final - Amostra 2</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 3">
                <span>Produto Final - Amostra 3</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 4">
                <span>Produto Final - Amostra 4</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 5">
                <span>Produto Final - Amostra 5</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 6">
                <span>Produto Final - Amostra 6</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 7">
                <span>Produto Final - Amostra 7</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 8">
                <span>Produto Final - Amostra 8</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 9">
                <span>Produto Final - Amostra 9</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

            <label class="PRODUTO FINAL - AMOSTRA 10">
                <span>Produto Final - Amostra 10</span>
                <input type="number" value="" (input)="onInputChange($event)">
                <p>* Obrigatório</p>
            </label>

        </div>

        <label class="A etiqueta está conforme?">
            <span>A etiqueta está conforme?</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option value="true">Conforme</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="Foto da etiqueta">
            <span>Foto da etiqueta</span>
            <input type="file" value="" (input)="onInputFileChange($event)" accept="image/*">
            <p>* Obrigatório</p>
            <p></p>
        </label>

        <label class="Foto da etiqueta 2">
            <span>Foto da etiqueta 2</span>
            <input type="file" value="" (input)="onInputFileChange($event)" accept="image/*">
            <p></p>
        </label>

        <label class="VALIDADE">
            <span>Valido até</span>
            <input type="date" value="" (input)="onInputChange($event)">
            <p>* Obrigatório</p>
        </label>

        <label class="EMBALAGEM">
            <span>Embalagem</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option *ngFor="let embalagem of embalagensSelect" [value]="embalagem">{{ embalagem }}</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="EMBALAGEM SECUNDÁRIA">
            <span>Embalagem Secundária</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option *ngFor="let embalagem of embalagensSelect" [value]="embalagem">{{ embalagem }}</option>
            </select>
        </label>

        <label class="EQUIPAMENTO">
            <span>Equipamento</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option *ngFor="let equipamento of equipamentosSelect" [value]="equipamento">{{ equipamento }}</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="Compatibilidade de lote e validade">
            <span>Compatibilidade de lote e validade</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option value="true">Conforme</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="Itens conformes" id="itens-conformes">
            <span>Itens conformes</span>
            <label class="checkbox">
                <input type="checkbox" class="Embalagem primária"
                    (input)="this.onInputItensConformesChange($event)"><span>Embalagem primária</span>
            </label>
            <label class="checkbox">
                <input type="checkbox" class="Embalagem secundária"
                    (input)="this.onInputItensConformesChange($event)"><span>Embalagem secundária</span>
            </label>
            <label class="checkbox">
                <input type="checkbox" class="Embalagem terciária"
                    (input)="this.onInputItensConformesChange($event)"><span>Embalagem terciária</span>
            </label>
            <label class="checkbox">
                <input type="checkbox" class="Compatibilidade de identificação de sabor das embalagens"
                    (input)="this.onInputItensConformesChange($event)"><span>Compatibilidade de
                    identificação de sabor das embalagens</span>
            </label>
            <label class="checkbox">
                <input type="checkbox" class="Conferência de número de produto na caixa de embarque"
                    (input)="this.onInputItensConformesChange($event)"><span>Conferência
                    de número de produto na caixa de embarque</span>
            </label>
            <label class="checkbox">
                <input type="checkbox" class="Qualidade de impressão e selagem"
                    (input)="this.onInputItensConformesChange($event)"><span>Qualidade de impressão e
                    selagem</span>
            </label>
            <p>* Obrigatório</p>
        </label>

        <label class="Houve algum item não conforme?">
            <span>Houve algum item não conforme?</span>
            <select (input)="showWarning($event)">
                <option value="">Selecione</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="DE ACORDO?">
            <span>Ao enviar este documento, valido a veracidade dos dados preenchidos e, caso tenha havido alguma não
                conformidade, avisei de imediato o time de Qualidade.</span>
            <select (input)="onInputChange($event)">
                <option value="">Selecione</option>
                <option value="true">Sim</option>
            </select>
            <p>* Obrigatório</p>
        </label>

        <label class="Documento verificado e liberado pelo responsável?">
            <span>Documento verificado e liberado pelo responsável?</span>
            <input type="text" value="" list="liberado_nome_sugestoes" (input)="onInputChange($event)">
            <datalist id="liberado_nome_sugestoes">
                <option value="Stefány da Silva"></option>
            </datalist>
            <p>* Obrigatório</p>
        </label>
    </form>

    <p id="info-add-data" class="black"></p>

    <div class="buttons">
        <button class="" (click)="submitData()" [disabled]="isLoading">Submeter</button>
        <button class="outlined" popoverTarget="add-data" [disabled]="isLoading">Salvar Rascunho</button>
    </div>
</div>

<div id="reportar-qualidade-warning" class="reportar-qualidade-warning" popover>
    <p>Por favor, reportar para Qualidades.</p>
    <div class="buttons">
        <button class="outlined" (click)="closeWarning()">Fechar</button>
    </div>
</div>

<loading [isLoading]="isLoading"></loading>