<main>
    <h1>
        <span>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.14194 16.5002H12.8581C12.9791 15.3984 13.541 14.4891 14.4531 13.4963C14.5567 13.3845 15.2158 12.7016 15.2937 12.6044C15.9411 11.7955 16.3469 10.8203 16.4644 9.79093C16.5818 8.76157 16.4062 7.71996 15.9576 6.78606C15.509 5.85217 14.8058 5.06396 13.9289 4.51222C13.052 3.96047 12.0371 3.66763 11.001 3.6674C9.96497 3.66718 8.94994 3.95959 8.0728 4.51097C7.19566 5.06234 6.49209 5.85024 6.04311 6.78395C5.59414 7.71765 5.41802 8.75918 5.53503 9.7886C5.65205 10.818 6.05744 11.7934 6.70452 12.6026C6.78336 12.7006 7.44427 13.3845 7.54602 13.4954C8.45902 14.4891 9.02094 15.3984 9.14194 16.5002ZM12.8334 18.3336H9.16669V19.2502H12.8334V18.3336ZM5.27452 13.7502C4.4112 12.6716 3.87014 11.371 3.71368 9.99827C3.55722 8.62557 3.79172 7.23658 4.39017 5.99132C4.98862 4.74606 5.92667 3.69519 7.09627 2.95976C8.26586 2.22432 9.61942 1.83425 11.001 1.83447C12.3826 1.83469 13.736 2.2252 14.9054 2.96101C16.0748 3.69681 17.0125 4.74799 17.6105 5.99344C18.2086 7.23889 18.4426 8.62795 18.2857 10.0006C18.1288 11.3733 17.5874 12.6737 16.7237 13.7521C16.1554 14.4597 14.6667 15.5836 14.6667 16.9586V19.2502C14.6667 19.7365 14.4735 20.2028 14.1297 20.5466C13.7859 20.8904 13.3196 21.0836 12.8334 21.0836H9.16669C8.68046 21.0836 8.21415 20.8904 7.87033 20.5466C7.52651 20.2028 7.33336 19.7365 7.33336 19.2502V16.9586C7.33336 15.5836 5.84377 14.4597 5.27452 13.7502ZM11.9167 9.17055H14.2084L10.0834 14.6706V11.0039H7.79169L11.9167 5.50022V9.17147V9.17055Z"
                    fill="white" />
            </svg>
            &nbsp;
            Insights
        </span>
        <quick-access-buttons></quick-access-buttons>
    </h1>

    <div class="warning">
        <span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.56628 3.15644L6.69938 3.22299C7.23733 3.50028 7.67545 3.9384 7.95274 4.47635L8.01929 4.60945C8.28549 5.1363 9.03972 5.1363 9.31147 4.60945L9.37802 4.47635C9.65531 3.9384 10.0934 3.50028 10.6314 3.22299L10.7645 3.15644C11.2913 2.89024 11.2913 2.13601 10.7645 1.86426L10.6314 1.79771C10.0934 1.52042 9.65531 1.0823 9.37802 0.544354L9.31147 0.411254C9.04527 -0.1156 8.29103 -0.1156 8.01929 0.411254L7.95274 0.544354C7.67545 1.0823 7.23733 1.52042 6.69938 1.79771L6.56628 1.86426C6.03943 2.13046 6.03943 2.8847 6.56628 3.15644Z"
                    fill="#2853E8" />
                <path
                    d="M7.61444 8.35289C8.15794 8.0756 8.15794 7.30472 7.61444 7.02743L6.80475 6.6115C6.08379 6.23992 5.49593 5.65207 5.12436 4.93111L4.70843 4.12142C4.43113 3.57792 3.66026 3.57792 3.38297 4.12142L2.96703 4.93111C2.59546 5.65207 2.0076 6.23992 1.28665 6.6115L0.476955 7.02743C-0.066537 7.30472 -0.066537 8.0756 0.476955 8.35289L1.28665 8.76883C2.0076 9.1404 2.59546 9.72825 2.96703 10.4492L3.38297 11.2589C3.66026 11.8024 4.43113 11.8024 4.70843 11.2589L5.12436 10.4492C5.49593 9.72825 6.08379 9.1404 6.80475 8.76883L7.61444 8.35289Z"
                    fill="#2853E8" />
                <path
                    d="M18.0184 11.6693L17.1976 11.2478C16.2049 10.7431 15.4119 9.95009 14.9072 8.95738L14.4857 8.1366C14.1142 7.40455 13.371 6.94979 12.5502 6.94979C11.7295 6.94979 10.9863 7.40455 10.6147 8.1366L10.1933 8.95738C9.68859 9.95009 8.89553 10.7431 7.90283 11.2478L7.08204 11.6693C6.34999 12.0409 5.89523 12.784 5.89523 13.6048C5.89523 14.4256 6.34999 15.1687 7.08204 15.5403L7.90283 15.9618C8.89553 16.4664 9.68859 17.2595 10.1933 18.2522L10.6147 19.073C10.9863 19.805 11.7295 20.2598 12.5502 20.2598C13.371 20.2598 14.1142 19.805 14.4857 19.073L14.9072 18.2522C15.4119 17.2595 16.2049 16.4664 17.1976 15.9618L18.0184 15.5403C18.7505 15.1687 19.2052 14.4256 19.2052 13.6048C19.2052 12.784 18.7505 12.0409 18.0184 11.6693ZM17.2642 14.0595L16.4434 14.481C15.1346 15.1465 14.092 16.1891 13.4265 17.498L13.005 18.3188C12.8774 18.5683 12.6445 18.596 12.5502 18.596C12.456 18.596 12.223 18.5683 12.0955 18.3188L11.674 17.498C11.0085 16.1891 9.96588 15.1465 8.65706 14.481L7.83628 14.0595C7.58671 13.932 7.55898 13.6991 7.55898 13.6048C7.55898 13.5105 7.58671 13.2776 7.83628 13.15L8.65706 12.7285C9.96588 12.063 11.0085 11.0204 11.674 9.71162L12.0955 8.89083C12.223 8.64127 12.456 8.61354 12.5502 8.61354C12.6445 8.61354 12.8774 8.64127 13.005 8.89083L13.4265 9.71162C14.092 11.0204 15.1346 12.063 16.4434 12.7285L17.2642 13.15C17.5138 13.2776 17.5415 13.5105 17.5415 13.6048C17.5415 13.6991 17.5138 13.932 17.2642 14.0595Z"
                    fill="#2853E8" />
            </svg>
            Os insights são gerados automaticamente por IA. Recomendamos validar os dados antes de tomar decisões.
        </span>
    </div>

    <section class="insights">
        <div *ngFor="let insight of data" [attr.aria-disabled]="isLoading">
            <p class="insight">
                <span [innerHTML]="sanitizeHtml(insight.insight)"></span>
                <br>
                <span>Gerado em: <span>{{ formatDate(insight.data) }}</span></span>
            </p>
            <p class="type">
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.56628 3.15644L6.69938 3.22299C7.23733 3.50028 7.67545 3.9384 7.95274 4.47635L8.01929 4.60945C8.28549 5.1363 9.03972 5.1363 9.31147 4.60945L9.37802 4.47635C9.65531 3.9384 10.0934 3.50028 10.6314 3.22299L10.7645 3.15644C11.2913 2.89024 11.2913 2.13601 10.7645 1.86426L10.6314 1.79771C10.0934 1.52042 9.65531 1.0823 9.37802 0.544354L9.31147 0.411254C9.04527 -0.1156 8.29103 -0.1156 8.01929 0.411254L7.95274 0.544354C7.67545 1.0823 7.23733 1.52042 6.69938 1.79771L6.56628 1.86426C6.03943 2.13046 6.03943 2.8847 6.56628 3.15644Z"
                        fill="#2853E8" />
                    <path
                        d="M7.61444 8.35289C8.15794 8.0756 8.15794 7.30472 7.61444 7.02743L6.80475 6.6115C6.08379 6.23992 5.49593 5.65207 5.12436 4.93111L4.70843 4.12142C4.43113 3.57792 3.66026 3.57792 3.38297 4.12142L2.96703 4.93111C2.59546 5.65207 2.0076 6.23992 1.28665 6.6115L0.476955 7.02743C-0.066537 7.30472 -0.066537 8.0756 0.476955 8.35289L1.28665 8.76883C2.0076 9.1404 2.59546 9.72825 2.96703 10.4492L3.38297 11.2589C3.66026 11.8024 4.43113 11.8024 4.70843 11.2589L5.12436 10.4492C5.49593 9.72825 6.08379 9.1404 6.80475 8.76883L7.61444 8.35289Z"
                        fill="#2853E8" />
                    <path
                        d="M18.0184 11.6693L17.1976 11.2478C16.2049 10.7431 15.4119 9.95009 14.9072 8.95738L14.4857 8.1366C14.1142 7.40455 13.371 6.94979 12.5502 6.94979C11.7295 6.94979 10.9863 7.40455 10.6147 8.1366L10.1933 8.95738C9.68859 9.95009 8.89553 10.7431 7.90283 11.2478L7.08204 11.6693C6.34999 12.0409 5.89523 12.784 5.89523 13.6048C5.89523 14.4256 6.34999 15.1687 7.08204 15.5403L7.90283 15.9618C8.89553 16.4664 9.68859 17.2595 10.1933 18.2522L10.6147 19.073C10.9863 19.805 11.7295 20.2598 12.5502 20.2598C13.371 20.2598 14.1142 19.805 14.4857 19.073L14.9072 18.2522C15.4119 17.2595 16.2049 16.4664 17.1976 15.9618L18.0184 15.5403C18.7505 15.1687 19.2052 14.4256 19.2052 13.6048C19.2052 12.784 18.7505 12.0409 18.0184 11.6693ZM17.2642 14.0595L16.4434 14.481C15.1346 15.1465 14.092 16.1891 13.4265 17.498L13.005 18.3188C12.8774 18.5683 12.6445 18.596 12.5502 18.596C12.456 18.596 12.223 18.5683 12.0955 18.3188L11.674 17.498C11.0085 16.1891 9.96588 15.1465 8.65706 14.481L7.83628 14.0595C7.58671 13.932 7.55898 13.6991 7.55898 13.6048C7.55898 13.5105 7.58671 13.2776 7.83628 13.15L8.65706 12.7285C9.96588 12.063 11.0085 11.0204 11.674 9.71162L12.0955 8.89083C12.223 8.64127 12.456 8.61354 12.5502 8.61354C12.6445 8.61354 12.8774 8.64127 13.005 8.89083L13.4265 9.71162C14.092 11.0204 15.1346 12.063 16.4434 12.7285L17.2642 13.15C17.5138 13.2776 17.5415 13.5105 17.5415 13.6048C17.5415 13.6991 17.5138 13.932 17.2642 14.0595Z"
                        fill="#2853E8" />
                </svg>
                &nbsp;
                Recomendações
            </p>
        </div>
    </section>
</main>