import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, LoadingComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {
  isLoading = false;

  constructor(private title: Title) {
    this.title.setTitle('Login Predicta');
  }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    remember: new FormControl(true)
  });
  warning: string = '';

  ngOnInit() {
    this.ifLogged();

    (document.querySelector('main>form>label') as HTMLElement).focus();
    const labels: HTMLLabelElement[] = document.querySelectorAll('main>form>label') as any;
    labels.forEach((label, index) => {
      label.addEventListener('keydown', (event: any) => {
        if (event.key === 'Enter') (labels[index].nextElementSibling as HTMLElement).focus();
      });
    });
  }

  async login() {
    this.warning = '';

    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const email = this.loginForm.get('email')?.value || '';
    const password = this.loginForm.get('password')?.value || '';
    const remember = this.loginForm.get('remember')?.value || false;

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    try {
      remember ? await setPersistence(auth, browserLocalPersistence) : await setPersistence(auth, browserSessionPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      window.location.href = '/controle';
    } catch (error) {
      this.warning = "Login ou senha inválidos.";
    } finally {
      this.isLoading = false;
    }
  }

  async ifLogged() {
    this.isLoading = true;
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) window.location.href = '/controle';
      this.isLoading = false;
    });
    return false;
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyBYeEfjL_dbgN-CKeyWqLNW1IunFxHmjxs",
  authDomain: "ideia-tech.firebaseapp.com",
  projectId: "ideia-tech",
  storageBucket: "ideia-tech.appspot.com",
  messagingSenderId: "818775631889",
  appId: "1:818775631889:web:231d6a249422230d5029b0"
};