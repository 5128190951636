import { Component } from '@angular/core';
import { LoginService } from '../login.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'side-nav',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.css'
})
export class SideNavComponent {
  constructor(public router: Router, private loginService: LoginService) { }

  isLogInPage: boolean = false;

  ngOnInit() {
    this.isLogInPage = window.location.pathname === '/';
  }

  logOut() {
    this.loginService.logout();
  }
}
