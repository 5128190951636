import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { LoginService } from '../login.service';

@Component({
  selector: 'reprocessos',
  standalone: true,
  imports: [],
  templateUrl: './reprocessos.component.html',
  styleUrl: './reprocessos.component.css'
})
export class ReprocessosComponent {

  constructor(private loginService: LoginService) {
  }

  isLoading: boolean = true;
  formatador = new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  async getData() {
    this.isLoading = true;

    const total_em_estoque_kg_element = document.getElementById('total_em_estoque_kg');
    const total_em_estoque_percent_element = document.getElementById('total_em_estoque_percent');
    const total_reprocessos_gerados_kg_element = document.getElementById('total_reprocessos_gerados_kg');
    const produto_mais_reprocessado_nome_element = document.getElementById('produto_mais_reprocessado_nome');
    const produto_mais_reprocessado_kg_element = document.getElementById('produto_mais_reprocessado_kg');
    const produto_mais_reprocessado_percent_element = document.getElementById('produto_mais_reprocessado_percent');
    const top_5_produtos_reprocessos_estoque_element = document.getElementById('top_5_produtos_reprocessos_estoque') as HTMLCanvasElement;
    const top_produto_reprocessos_estoque_element = document.getElementById('top_produto_reprocessos_estoque');

    try {
      const response = await axios.get(environment.apiUrl + '/reprocessos/infos', { headers: { Authorization: 'Bearer ' + this.loginService.token } });
      const data = response.data;

      const total_em_estoque_kg = this.formatador.format(data.total_em_estoque_kg);
      const total_em_estoque_percent = this.formatador.format(data.total_em_estoque_percent);
      const total_reprocessos_gerados_kg = this.formatador.format(data.total_reprocessos_gerados_kg);
      const produto_mais_reprocessado_nome = data.produto_mais_reprocessado_kg_ultimos_3_meses ? Object.keys(data.produto_mais_reprocessado_kg_ultimos_3_meses)[0] : 'Nenhum produto reprocessado nos últimos 3 meses';
      const produto_mais_reprocessado_kg = this.formatador.format(data.produto_mais_reprocessado_kg_ultimos_3_meses ? Object.values(data.produto_mais_reprocessado_kg_ultimos_3_meses)[0] as number : 0);
      const produto_mais_reprocessado_percent = this.formatador.format(data.produto_mais_reprocessado_percent_ultimos_3_meses ? Object.values(data.produto_mais_reprocessado_percent_ultimos_3_meses)[0] as number : 0);
      const top_5_produtos_reprocessos_estoque_nome_kg = data.top_5_produtos_reprocessos_estoque_kg ? Object.entries(data.top_5_produtos_reprocessos_estoque_kg) : [];
      const top_5_produtos_reprocessos_estoque_nome_percent = data.top_5_produtos_reprocessos_estoque_percent ? Object.entries(data.top_5_produtos_reprocessos_estoque_percent) : [];
      const top_produto_reprocessos_estoque = data.top_5_produtos_reprocessos_estoque_kg ? Object.keys(data.top_5_produtos_reprocessos_estoque_kg[0])[0] : 'Nenhum reprocesso em estoque';

      if (total_em_estoque_kg_element) total_em_estoque_kg_element.innerHTML = total_em_estoque_kg;
      if (total_em_estoque_percent_element) total_em_estoque_percent_element.innerHTML = total_em_estoque_percent;
      if (total_reprocessos_gerados_kg_element) total_reprocessos_gerados_kg_element.innerHTML = total_reprocessos_gerados_kg;
      if (produto_mais_reprocessado_nome_element) produto_mais_reprocessado_nome_element.innerHTML = produto_mais_reprocessado_nome;
      if (produto_mais_reprocessado_kg_element) produto_mais_reprocessado_kg_element.innerHTML = produto_mais_reprocessado_kg;
      if (produto_mais_reprocessado_percent_element) produto_mais_reprocessado_percent_element.innerHTML = produto_mais_reprocessado_percent;
      if (top_5_produtos_reprocessos_estoque_element) this.generateChart(top_5_produtos_reprocessos_estoque_element, top_5_produtos_reprocessos_estoque_nome_kg as ChartData, top_5_produtos_reprocessos_estoque_nome_percent as ChartData);
      if (top_produto_reprocessos_estoque_element) top_produto_reprocessos_estoque_element.innerHTML = top_produto_reprocessos_estoque;
    } catch (error) {
      if (total_em_estoque_kg_element) total_em_estoque_kg_element.innerHTML = 'Erro';
      if (total_em_estoque_percent_element) total_em_estoque_percent_element.innerHTML = 'Erro';
      if (total_reprocessos_gerados_kg_element) total_reprocessos_gerados_kg_element.innerHTML = 'Erro';
      if (produto_mais_reprocessado_nome_element) produto_mais_reprocessado_nome_element.innerHTML = 'Erro';
      if (produto_mais_reprocessado_kg_element) produto_mais_reprocessado_kg_element.innerHTML = 'Erro';
      if (produto_mais_reprocessado_percent_element) produto_mais_reprocessado_percent_element.innerHTML = 'Erro';
    } finally {
      this.isLoading = false;
    }
  }


  generateChart(canvasElement: HTMLCanvasElement, dataKg: ChartData, dataPercentage: ChartData): Chart {
    const labels: string[] = [];
    const valuesKg: number[] = [];
    const valuesPercentage: number[] = [];

    dataKg.forEach(([label, valueObj], index) => {
      const keyKg = Object.keys(valueObj)[0];
      const valueKg = valueObj[keyKg];
      labels.push(keyKg);
      valuesKg.push(valueKg);

      const valuePercentage = Object.values(dataPercentage[index][1])[0];
      valuesPercentage.push(valuePercentage);
    });

    const chart = new Chart(canvasElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Estoque',
            data: valuesKg,
            backgroundColor: '#2853E8',
            borderWidth: 0,
            borderRadius: 4,
            barThickness: 'flex',
            maxBarThickness: 4,
          },
        ]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const value = tooltipItem.raw as number;
                return `${this.formatador.format(value)} kg (${this.formatador.format(valuesPercentage[tooltipItem.dataIndex])}%)`;
              }
            }
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            ticks: {
              font: {
                size: 12,
                weight: 500,
              },
              color: '#23272ECC',
              autoSkip: false,
            },
            grid: {
              display: false
            },
          }
        },
        layout: {
          padding: 0
        }
      }
    });

    return chart;
  }

  ngOnInit() {
    Chart.register(...registerables, annotationPlugin);

    this.loginService.ifLoggedIn(this.getData.bind(this));
  }

}

type DataEntry = [string, Record<string, number>];

type ChartData = DataEntry[];