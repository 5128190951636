import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ControleComponent } from './controle/controle.component';
import { PerfilComponent } from './perfil/perfil.component';
import { ControleDadosComponent } from './controle-dados/controle-dados.component';

export const routes: Routes = [
    { path: '', component: LoginComponent },
    { path: 'controle', component: ControleComponent },
    { path: 'controle-dados', component: ControleDadosComponent },
    { path: 'perfil', component: PerfilComponent },
];
