import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'predicta-front';

  ngOnInit() {
    this.verifyUser();
  }

  async verifyUser() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const user_data = (await axios.get(environment.apiUrl + '/whoami', { headers: { Authorization: `Bearer ${token}` } })).data;
        if (!user_data.ativo) (document.querySelector('dialog#deactivated-user') as HTMLDialogElement)?.showModal();
      }
      else {
      }
    });
  }

  async logOut() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    await auth.signOut();
    window.location.href = '/';
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyBYeEfjL_dbgN-CKeyWqLNW1IunFxHmjxs",
  authDomain: "ideia-tech.firebaseapp.com",
  projectId: "ideia-tech",
  storageBucket: "ideia-tech.appspot.com",
  messagingSenderId: "818775631889",
  appId: "1:818775631889:web:231d6a249422230d5029b0"
};